import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppApi } from "../../app-api";
import { PlayerService } from "../../services/player/player.service";
import Swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import dataPlayer from "./../search-metric/searchPlayerData.json";
import * as Highcharts from "highcharts";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Title } from "@angular/platform-browser";
import metrics from './../player-dashboard/playerDashboardData.json';
import exporting from 'highcharts/modules/exporting';
// exporting(Highcharts)
@Component({
  selector: "app-player-vs-player",
  templateUrl: "./player-vs-player.component.html",
  styleUrls: ["./player-vs-player.component.scss"]
})
export class PlayerVsPlayerComponent implements OnInit {
  allMetrics: any = metrics as any
  dropdownSettings: IDropdownSettings;
  selectedPositions = [];
  metric: any = dataPlayer as any;
  searchPlayerOneFilterForm: FormGroup;
  searchPlayerTwoFilterForm: FormGroup;
  filteredDetailOne: any = [];
  filteredDetailTwo: any = [];
  playerList: string = "";
  topLeagues: any = {};
  showLoader: boolean = false;
  showFilterLoader: boolean = false;
  playerOneDetails: any;
  playerTwoDetails: any;
  playerOneStats: any;
  playerTwoStats: any;
  playerOneId;
  playerTwoId;
  showLoaderDetailsOne: boolean = false;
  showLoaderDetailsTwo: boolean = false;
  playerOneSeason = 2021;
  playerTwoSeason = 2021;
  playerOneTeam: String = "";
  playerTwoTeam: String = "";
  playerOneLeagueId: Number = 0;
  playerTwoLeagueId: Number = 0;
  playerOneLeagues = [];
  playerTwoLeagues = [];
  topSeason: Number = 2021;
  window: Window;
  openPlayerTwo: boolean = false;
  p1StatsByTeam: any = [];
  p2StatsByTeam: any = [];
  p1Teams: any = []
  p2Teams: any = []
  playerOnePercentile = [];
  playerTwoPercentile = [];
  settings = {
    hideSubHeader: true,
    actions: false,
    attr: {
      class: "table table-striped table-dark"
    },
    columns: {
      position: {
        title: "POSITION",
        filter: false
      },
    }
  };
  xAxisCategories = []//['Goals', 'Assists', 'Shots on target', 'Successful dribbles', 'Total passes', 'Key passes', 'Total tackles', 'Blocks', 'Interceptions', 'Total duels', 'Won duels'];
  //per90StatsKeys = ['accuracy', 'assistsPer90', "blocksPer90", "fouls", "goalsPer90", "interceptionsPer90", "keyPassesPer90", "panalityCommitted", 'red', "shotsOnTargetPer90", "successfulDribblesPer90", "tacklesPer90", "totalPassesPer90", "wonDuelsPer90", "yellow"];
  percentileObj = {};
  STATS_PER_90_COLUMNS = [
    {
      key: "goals",
      value: "total",
      actual: "goals",
      target: "goalsPer90",
    },
    {
      key: "goals",
      value: "assists",
      actual: "assists",
      target: "assistsPer90",
    },
    {
      key: "shots",
      value: "on",
      actual: "shotsOnTarget",
      target: "shotsOnTargetPer90",
    },
    {
      key: "dribbles",
      value: "success",
      actual: "successfulDribbles",
      target: "successfulDribblesPer90",
    },
    {
      key: "passes",
      value: "total",
      actual: "totalPasses",
      target: "totalPassesPer90",
    },
    {
      key: "passes",
      value: "key",
      actual: "keyPasses",
      target: "keyPassesPer90",
    },
    {
      key: "tackles",
      value: "total",
      actual: "totalTackles",
      target: "tacklesPer90",
    },
    {
      key: "tackles",
      value: "blocks",
      actual: "blocksTackles",
      target: "blocksPer90",
    },
    {
      key: "tackles",
      value: "interceptions",
      actual: "interceptionsTackles",
      target: "interceptionsPer90",
    },
    {
      key: "duels",
      value: "total",
      actual: "totalDuels",
      target: "totalDuelsPer90",
    },
    {
      key: "duels",
      value: "won",
      actual: "wonDuels",
      target: "wonDuelsPer90",
    }
  ];
  randomPlayersList = ['df7b767f-9a27-4c89-a671-1100956d4fd7', 'c34f43a1-4b49-45d9-a39b-84609a5c9ede', '3fba1fe1-15b6-4278-b8a8-eac9115d3ff0', '38c216d3-7a8e-4169-a09f-db6bd280b8f2', '2436f421-31bb-47a4-a696-99f0df80b661', 'ee8a0648-150e-4c7c-8d4f-2fb114f5fe0f', '05e62b38-4774-4a2f-836c-010ecb23140d', 'f1092fd1-50d0-4253-b115-dc487f59c7a9', '4f3772f7-e427-44a9-8c33-8152266fd245', 'a0da4d3f-dff8-4e9d-859d-278ac3a8d96e', '7254cc82-76cf-43cc-8e2e-95c951e2a46d', 'ae9e4bb0-3f0a-4668-8f54-115d2c49e036', 'bdd33ca2-3f8b-417c-baf4-81833dc077e8'];
  yAxis: Number[];
  position: any = "All";
  public comperisonOptions: any = {
    chart: {
      polar: true,
      height: "50%"
    },
    pane: {
      startAngle: 0,
      endAngle: 360
    },
    xAxis: {
      labels: {
        formatter: function () {
          this.xAxisCategories = [];
          for (let k of this.selectedMetric) {
            this.xAxisCategories.push(k.name)
          }
          return this.xAxisCategories[this.value];
          // return this.selectedMetric[this.value]
          // return this.xAxisCategories[this.value]
        },
        style: {
          color: "#fff",
          fontSize: "15px"
        },
        useHTML: true
      }
    },
    yAxis: {
      min: 0,
      max: 100
    },
    series: [
      {
        type: "area",
        color: "#e49797",
        name: "Area",
        data: []
      },
      {
        type: "area",
        color: "#2887e6",
        name: "Area",
        data: []
      }
    ]
  };
  selectedMetrics: any = []
  currentDate;
  currentSeason;
  playerType = 1;
  metricsDropdownSettings = {};

  constructor(private titleService: Title, private playerService: PlayerService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.titleService.setTitle("Scoutcharts-Player-vs-Player");
    this.currentDate = new Date();
    this.currentSeason = this.getCurrentSeason();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: true
    };
    this.metricsDropdownSettings = {
      singleSelection: false,
      text: "Select Fields",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: '',
      enableSearchFilter: false,
      badgeShowLimit: 1,
      groupBy: "category",
      limitSelection: 12,
      selectGroup: false
    };
    for (const position of this.metric.positions) {
      this.selectedPositions.push(position);
    }
    for (let i = 0; i < this.allMetrics.ChartMetrics.length; i++) {
      let metric = this.allMetrics.ChartMetrics[i];
      if(metric.isShow){
        this.selectedMetrics.push(metric);
      }
    }
  }

  ngOnInit(): void {

    Highcharts.chart("container", this.comperisonOptions);
    this.playerOneId = this.activatedRoute.snapshot.queryParamMap.get("player1");
    this.playerTwoId = this.activatedRoute.snapshot.queryParamMap.get("player2");
    this.playerOneSeason = Number(this.activatedRoute.snapshot.queryParamMap.get("p1seasons")) || this.currentSeason;
    this.playerTwoSeason = Number(this.activatedRoute.snapshot.queryParamMap.get("p2seasons")) || this.currentSeason;
    this.playerOneLeagueId = Number(this.activatedRoute.snapshot.queryParamMap.get("playerOneLeagueId")) || this.playerOneLeagueId;
    this.playerTwoLeagueId = Number(this.activatedRoute.snapshot.queryParamMap.get("playerTwoLeagueId")) || this.playerTwoLeagueId;

    this.getTopLeagues(true); // function to get the top leagues
    this.searchPlayerOneFilterForm = new FormGroup({
      playerOneSearch: new FormControl("", [Validators.required]),
      playerOneSeason: new FormControl(this.playerOneSeason, [Validators.required])
    });
    this.searchPlayerTwoFilterForm = new FormGroup({
      playerTwoSearch: new FormControl("", [Validators.required]),
      playerTwoSeason: new FormControl(this.playerTwoSeason, [Validators.required])
    });

    this.loadPlayerList();
    if (this.playerOneSeason) {
      this.searchPlayerOneFilterForm.patchValue({
        playerOneSeason: this.playerOneSeason
      });
    }

    if (this.playerTwoSeason) {
      this.searchPlayerTwoFilterForm.patchValue({
        playerTwoSeason: this.playerTwoSeason
      });
    }

  }
  async loadPlayerList(){
    if (!localStorage.getItem("dataStorage")) {
      await this.getPlayerList(); //Function to get player list
    } else {
      this.playerList = localStorage.getItem("dataStorage");
    }

  }
  replaceDiacritics(str) {

    var diacritics = [
      { char: 'A', base: /[\300-\306]/g },
      { char: 'a', base: /[\340-\346]/g },
      { char: 'E', base: /[\310-\313]/g },
      { char: 'e', base: /[\350-\353]/g },
      { char: 'I', base: /[\314-\317]/g },
      { char: 'i', base: /[\354-\357]/g },
      { char: 'O', base: /[\322-\330]/g },
      { char: 'o', base: /[\362-\370]/g },
      { char: 'U', base: /[\331-\334]/g },
      { char: 'u', base: /[\371-\374]/g },
      { char: 'N', base: /[\321]/g },
      { char: 'n', base: /[\361]/g },
      { char: 'C', base: /[\307]/g },
      { char: 'c', base: /[\347]/g }
    ]

    diacritics.forEach(function (letter) {
      str = str.replace(letter.base, letter.char);
    });

    return str;
  };
  /**
   * Function to filter players
   * @param value
   */
  filterPlayers(value, playerType) {
    this.playerType = playerType;
    if (value && value.length >= 3) {
      let filteredPlayers = this.playerList
        ? JSON.parse(this.playerList).filter(
          searchFilter => {
            let name = searchFilter.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
            let searchValueArr = value.split(" ");
            let matched = true;
            if (searchValueArr.length) {
              for (let k of searchValueArr) {
                name = this.replaceDiacritics(name);

                if (!name.includes(k.toLowerCase())) {
                  matched = false;
                }
              }
              return matched;
            } else {
              return name.includes(value.toLowerCase());
            }
          }

        )
        : [];
      if (playerType === 1) {
        this.filteredDetailOne = filteredPlayers
      } else {
        this.filteredDetailTwo = filteredPlayers
      }
    } else {
      this.filteredDetailOne = [];
      this.filteredDetailTwo = [];
    }

  }
  setPosition() {
    if (this.selectedPositions.length === 2) {
      let p1 = this.selectedPositions[0].id;
      let p2 = this.selectedPositions[1].id;
      if ((p1 == "Defender" && p2 == "Attacker") || (p1 == "Midfielder" && p2 == "Attacker")) {
        this.position = `${p2}_${p1}`;
      } else if (p1 == "Midfielder" && p2 == "Defender") {
        this.position = `${p1}_${p2}`;
      } else if (p1 == "Defender" && p2 == "Midfielder") {
        this.position = `${p2}_${p1}`;
      } else {
        this.position = `${p1}_${p2}`;
      }
    } else if (this.selectedPositions.length === 1) {
      this.position = this.selectedPositions[0].id;
    } else {
      this.position = "All";
    }
  }
  onOnePositionSelect(position) {
    this.setPosition();
    this.reloadChart();
  }
  async onAllPositionSelect(position) {
    this.position = "All";
    this.reloadChart();
  }
  async onPositionDeSelect(position) {
    this.setPosition();
    this.reloadChart();
  }
  async reloadChart() {
    let p1stats = await this.getPlayerOneStat();
    this.playerOnePercentile = await this.calculatePercentile(p1stats);
    if (this.openPlayerTwo) {
      let p2stats = await this.getPlayerTwoStat();
      this.playerTwoPercentile = await this.calculatePercentile(p2stats);
    }
    this.renderHighChart()
  }
filterSelectedMetrics(item:any){
      this.selectedMetrics.sort((a,b) => (a.id - b.id))
      this.selectedMetrics;
}
  /**
   * Metric Dropdown event
   */
  onItemSelect(item: any) {
    this.filterSelectedMetrics(item)
    this.reloadChart();
  }
  async OnItemDeSelect(item: any) {
    if (this.selectedMetrics.length < 6) {
      this.selectedMetrics.push(item);
      this.filterSelectedMetrics(item)
    } else {
      this.reloadChart();
    }
  }


  /**
   * Function to get player list
   */
  async getPlayerList() {
    this.showLoader = true;
    await this.playerService.getApiWitoutToken(AppApi.playersList).then((response: any) => {
      this.showLoader = false;
      if (response.success) {
        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        localStorage.setItem("expireDate", JSON.stringify(expireDate));

        let dataStorage = JSON.stringify(response.data);
        localStorage.setItem("dataStorage", dataStorage);
        this.playerList = localStorage.getItem("dataStorage");
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }

  /**
   * Select Player from searching list by playerId
   * @param playerId
   */
  selectPlayer(playerId, playerType) {
    this.getPlayerDetail(playerId, playerType);
    if (playerType === 1) {
      this.playerOneTeam = "";
      this.playerOneLeagueId = 0;
      this.playerOneLeagues = [];
      this.playerOneId = playerId;
    } else {
      this.playerTwoTeam = "";
      this.playerTwoLeagueId = 0;
      this.playerTwoLeagues = [];
      this.playerTwoId = playerId;
    }
    let url = "";
    if (this.playerOneId && this.playerTwoId) {
      url = `/player-vs-player?player1=${this.playerOneId}&player2=${this.playerTwoId}`;
      // url = `/YqW4q3nLWDV9mwCg?player1=${this.playerOneId}&player2=${this.playerTwoId}`;
    } else if (this.playerOneId && !this.playerTwoId) {
      url = `/player-vs-player?player1=${this.playerOneId}`;
      // url = `/YqW4q3nLWDV9mwCg?player1=${this.playerOneId}`;
    } else {
      url = `/player-vs-player?player2=${this.playerTwoId}`;
      // url = `/YqW4q3nLWDV9mwCg?player2=${this.playerTwoId}`;
    }
    this.router.navigateByUrl(url);
  }

  /**
   * Fetch Player details on change season
   * @param event
   */
  onChangeSeason(seasonValue, playerType) {
    if (playerType === 1) {
      this.playerOneSeason = seasonValue;
      this.playerOneTeam = "";
      this.playerOneLeagueId = 0;
      this.playerOneLeagues = [];
      this.getPlayerDetail(this.playerOneId, playerType);
      this.router.navigate([], {
        queryParams: {
          p1seasons: seasonValue,
          playerOneLeagueId: 0
        },
        queryParamsHandling: "merge"
      });
    } else {
      this.playerTwoSeason = seasonValue;
      this.playerTwoTeam = "";
      this.playerTwoLeagueId = 0;
      this.playerTwoLeagues = [];
      this.getPlayerDetail(this.playerTwoId, playerType);
      this.router.navigate([], {
        queryParams: {
          p2seasons: seasonValue,
          playerTwoLeagueId: 0
        },
        queryParamsHandling: "merge"
      });
    }
  }
  async onChangeLeague(league, playerType) {
    if (playerType === 1) {
      this.playerOneLeagueId = league;
      let stats = await this.getPlayerOneStat();
      this.playerOnePercentile = await this.calculatePercentile(stats);
      this.renderHighChart()
      this.router.navigate([], {
        queryParams: {
          playerOneLeagueId: league
        },
        queryParamsHandling: "merge"
      });
    } else {
      this.playerTwoLeagueId = league;
      let stats = await this.getPlayerTwoStat();
      this.playerTwoPercentile = await this.calculatePercentile(stats);

      this.renderHighChart()
      this.router.navigate([], {
        queryParams: {
          playerTwoLeagueId: league
        },
        queryParamsHandling: "merge"
      });
    }
  }
  async onChangeTopSeason(topSeason) {
    this.topSeason = topSeason;
    await this.getTopLeagues();
    this.showLoaderDetailsOne = false;
    this.reloadChart();
  }
  /**
   * Add second player for comparison between two players
   */
  addPlayer() {
    this.showLoaderDetailsTwo = true;
    let playerListCount = this.randomPlayersList.length - 1;
    let random = Math.floor(Math.random() * playerListCount) + 1;
    this.playerTwoId = this.randomPlayersList[random]//JSON.parse(this.playerList)[randomPlayerselected].id;
    this.router.navigate([], {
      queryParams: {
        player2: this.playerTwoId
      },
      queryParamsHandling: "merge"
    });
    this.getPlayerDetail(this.playerTwoId, 2);
    this.openPlayerTwo = !this.openPlayerTwo;
  }
  removePlayer() {
    this.openPlayerTwo = !this.openPlayerTwo;
    this.openPlayerTwo = false;
    this.playerTwoId = "";
    this.playerTwoPercentile = [];
    this.selectPlayer(this.playerOneId, 1)
  }
  /**
   * Function to get the topLeagues
   */
  async getTopLeagues(isLoadPlayer = false) {
    // if (!localStorage.getItem("topLeagues")) {
    this.showLoader = true;
    this.showLoaderDetailsOne = true;
    await this.playerService.getApiWitoutToken(`${AppApi.topLeagues}?season=${this.topSeason}`).then(async (response: any) => {
      this.showLoader = false;
      if (response.success) {
        this.topLeagues = response.data;//localStorage.getItem("topLeagues");
        if (isLoadPlayer) {
          if (this.playerOneId) {
            this.showLoaderDetailsOne = true;
            this.getPlayerDetail(this.playerOneId, 1);
          }

          if (this.playerTwoId) {
            this.openPlayerTwo = true;
            this.showLoaderDetailsTwo = true;
            this.getPlayerDetail(this.playerTwoId, 2);
          }
        }
        return
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });

  }

  /**
   * Function to get the player details by PlayerId
   * @param playerId
   */
  async getPlayerDetail(playerId, playerType) {
    let payLoad: any = { id: playerId, leagueId: null };
    if (playerType === 1 && this.playerOneSeason) {
      this.showLoaderDetailsOne = true;
      payLoad.season = this.playerOneSeason;
    } else if (playerType === 2 && this.playerTwoSeason) {
      this.showLoaderDetailsTwo = true;
      payLoad.season = this.playerTwoSeason;
    }
    //let selectedTopSeasonLeague = await this.filterTopLeagueStats();
    this.playerService.postApiWitoutToken(AppApi.playerDetail, payLoad).then(async (response: any) => {

      this.showLoaderDetailsOne = false;
      this.showLoaderDetailsTwo = false;
      if (response.success && response.data) {
        if (playerType === 1) {
          this.playerOneDetails = response.data;
          this.playerOneLeagues = response.data.leagues;
          this.p1StatsByTeam = response.data.statistics_by_teams ? response.data.statistics_by_teams : [];
          if (this.p1StatsByTeam.length) {
            this.playerOneTeam = this.playerOneTeam ? this.playerOneTeam : this.p1StatsByTeam[0].team.name;
          }
          
          let all_stats: any = await this.getPlayerOneStat();
          if (all_stats) {
            this.playerOneLeagueId = this.playerOneLeagueId ? this.playerOneLeagueId : Number(all_stats.leagueId);
          }
          this.playerOnePercentile = await this.calculatePercentile(all_stats);

          this.searchPlayerOneFilterForm.reset();
        } else {
          this.playerTwoDetails = response.data;
          this.p2StatsByTeam = response.data.statistics_by_teams ? response.data.statistics_by_teams : [];
          this.playerTwoLeagues = response.data.leagues;
          if (this.p2StatsByTeam.length) {
            this.playerTwoTeam = this.playerTwoTeam ? this.playerTwoTeam : this.p2StatsByTeam[0].team.name;
          }
          let all_stats: any = await this.getPlayerTwoStat();
          if (all_stats) {
            this.playerTwoLeagueId = this.playerTwoLeagueId ? this.playerTwoLeagueId : Number(all_stats.leagueId);
          }
          this.playerTwoPercentile = await this.calculatePercentile(all_stats);
          this.searchPlayerTwoFilterForm.reset();
        }
        this.renderHighChart();

      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }

  async sumObjectsByKey(statistics, leagueId, pType) {
    let data = JSON.parse(JSON.stringify(statistics));
    let result = {};
    leagueId = leagueId ? leagueId : data.length ? data[0].leagueId : "";
    if (leagueId) {
      data = data.filter(x => x.leagueId == leagueId);
      if (data.length) {
        if (pType === 1) {
          this.playerOneTeam = data[0].team.name
        } else {
          this.playerTwoTeam = data[0].team.name
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].isCurrentPlaying) {
          if (pType === 1) {
            this.playerOneTeam = data[i].team.name
          } else {
            this.playerTwoTeam = data[i].team.name
          }
        }
        if (i > 0) {
          if (data[i].games.minutes) {
            result['games']['minutes'] = parseInt(result['games']['minutes'] + data[i].games.minutes)
            result['games']['position'] = data[i].games.position;
            // this.allMetrics.ChartMetrics
            // for (let k of this.STATS_PER_90_COLUMNS) {
            for (let k of this.allMetrics.ChartMetrics) {
              // let prev = result[k.key][k.value] ? result[k.key][k.value] : 0,
              //   next = data[i][k.key][k.value] ? data[i][k.key][k.value] : 0;

                let prev = result[k.apiKey] ? result[k.apiKey] : 0,
                next = data[i][k.apiKey] ? data[i][k.apiKey] : 0;

              result[k.apiKey] = prev ? parseFloat(prev) : 0 + next ? parseFloat(next) : 0;
            }
          }
        } else {
          result = { ...data[i] };
        }
      }
    }
    return result;
  };
  async getPlayerOneStat() {
    //Create team array if stats by team has length greater than 1
    let all_stats = {}, currentTeamPlayingStats = {};
    if (this.p1StatsByTeam.length) {
      let leagueId = this.playerOneLeagueId ? this.playerOneLeagueId : "";
      
      all_stats = await this.sumObjectsByKey(this.p1StatsByTeam, leagueId, 1);
    } else {
      all_stats = this.playerOneDetails.all_stats;
    }
    this.playerOneStats = all_stats;
    return all_stats;
    // if(this.p1StatsByTeam.length > 1){
    //   for(let k of this.p1StatsByTeam){
    //     if(k.isCurrentPlaying){
    //         currentTeamPlayingStats = k;
    //     }
    //   }
    // }else{
    //   currentTeamPlayingStats = all_stats
    // }
    // return currentTeamPlayingStats;
  }
  async getPlayerTwoStat() {
    //Create team array if stats by team has length greater than 1
    let all_stats = {}, currentTeamPlayingStats = {};

    if (this.p2StatsByTeam.length) {
      let leagueId = this.playerTwoLeagueId ? this.playerTwoLeagueId : "";
      all_stats = await this.sumObjectsByKey(this.p2StatsByTeam, leagueId, 2);
    } else {
      all_stats = this.playerTwoDetails.all_stats;
    }
    this.playerTwoStats = all_stats;
    return all_stats;
    // if(this.p2StatsByTeam.length > 1){
    //   for(let k of this.p2StatsByTeam){
    //     if(k.isCurrentPlaying){
    //         currentTeamPlayingStats = k;
    //     }
    //   }
    // }else{
    //   currentTeamPlayingStats = all_stats
    // }
    // return currentTeamPlayingStats;
  }
  async renderHighChart() {
    this.yAxis = await this.getYAxis();
    const _this = this;
    this.comperisonOptions.xAxis = {
      tickInterval:1,
      min: 0,
          max: _this.selectedMetrics.length,
      labels: {
        formatter: function () {
            return _this.setPlayerxAxis(_this.selectedMetrics[this.value], _this.playerOnePercentile[this.value], _this.playerTwoPercentile[this.value], _this.openPlayerTwo, 100)//selectedTopSeasonLeague[this.value]);
        },
      }
    };
    this.comperisonOptions.tooltip = {
      formatter: function () {
        return `<div><div>${_this.selectedMetrics[this.x].name}</div><br/><div><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${this.y}</div></div>`;
        // return `<div><div>${_this.xAxisCategories[this.x]}</div><br/><div><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${this.y}</div></div>`;
      }
    }
    this.comperisonOptions.plotOptions= {
          series: {
              pointStart: 0,
              pointInterval:1,
          },
          column: {
              pointPadding: 0,
              groupPadding: 0
          }
      },
    this.comperisonOptions.yAxis = _this.yAxis,
      this.comperisonOptions.series = [
        {
          type: "area",
          color: "#ff232357",
          name: this.playerOneDetails && this.playerOneDetails.personal_info ? this.playerOneDetails.personal_info.name : "",
          data: this.playerOnePercentile
        },
        {
          type: "area",
          color: "#49b2ff59",
          name: this.playerTwoDetails && this.playerTwoDetails.personal_info ? this.playerTwoDetails.personal_info.name : "",
          data: this.playerTwoPercentile
        }
      ];
    Highcharts.chart("container", this.comperisonOptions);

  }
  async getYAxis(): Promise<Number[]> {
    let yAxisArray = [];
    try {
      let firstAngle = (360/this.selectedMetrics.length), angle=0;
      // for (let k = 0; k < this.STATS_PER_90_COLUMNS.length; k++) {
      for (let k = 0; k < this.selectedMetrics.length; k++) {
        angle = k === 0 ? firstAngle : (angle + firstAngle);
        yAxisArray.push({ min: 0, max: 100, angle });
      }
      return yAxisArray;
    } catch (error) {
      return yAxisArray;
    }
  }
  async calculatePercentile(stats): Promise<Number[]> {
    let percentileArray = [];
    if (stats) {
      try {
        let selectedSeason = await this.getCurrentSeason();
        let percentileBySelectedSeason: any;
        for (const seasonalPercentile of this.topLeagues.percentile) {
          let key = Object.keys(seasonalPercentile);
          if (parseInt(key[0]) == selectedSeason) {
            percentileBySelectedSeason = seasonalPercentile[selectedSeason][this.position];
          }
        }
        // for (let k of this.STATS_PER_90_COLUMNS) {selectedMetrics
        for (let k of this.selectedMetrics) {
          let percentile = await this.percentRank(percentileBySelectedSeason[k.actual], stats[k.apiKey])//stats[k.key][k.value])
          percentileArray.push(parseFloat(percentile));
        }
        return percentileArray

      } catch (error) {
        return percentileArray
      }
    } else {
      return percentileArray
    }

  }

  percentRank = async (arr, p) => {

    //Formula to calculate percentile rank
    //Percent rank of 2 in the range A2:A11. Result is 0.333 because three values in the set are less than 2, and six are greater than 2. Because 2 is found in the range (cell A8), the number of values less than 2 is divided by the sum of the number of values less than 2 and the number of values greater than 2. That is, 3/(3+6)=0.333.
    let lessThanPCount = 0, greaterThanP = 0;
    for (let k = 0; k < arr.length; k++) {
      if (p > arr[k]) { lessThanPCount++; } else if (p <= arr[k]) { greaterThanP++ }
    }
    if (greaterThanP) {
      return ((lessThanPCount / (lessThanPCount + (greaterThanP - 1))) * 100).toFixed(1) + "0";
    } else {
      return "100";
    }
  }
  /**
   * Function to return the player chart Xaxis name
   * @param axisName 
   * @param playerOneData 
   * @param playerTwoData 
   * @param isCompare 
   */
  setPlayerxAxis(metric, playerOneData, playerTwoData, isCompare, maxValue) {
    let axisName = metric ? metric.name : '';
    let classAxis: any = axisName ? axisName.replace(/\s+/g, '-').toLowerCase() : axisName;
    if (isCompare && playerOneData >= 0 && playerTwoData >= 0) {
      return `<div class="total-nums ${classAxis}">
                <div class="xaxis-label">${axisName} <span class="player-red">${playerOneData}%</span> vs <span class="player-blue">${playerTwoData}%</span></div>
                <div class="maxValue" style="display:none" >${maxValue}</div>
              </div>`;
    } else if (playerOneData >= 0) {
      return `<div class="total-nums single-player-nums ${classAxis}">
      <div class="xaxis-label">${axisName} <span class="player-red">${playerOneData}%</span> </div>
      <div class="maxValue" style="display:none">${maxValue}</div>
    </div>`;
    } else if (playerTwoData >= 0) {
      return `<div class="total-nums single-player-nums ${classAxis}">
      <div class="xaxis-label">${axisName} <span class="player-blue">${playerTwoData}%</span> </div>
      <div class="maxValue" style="display:none">${maxValue}</div>
    </div>`;
    }
    else {
      return 0;
    }
  }

  /**
   * Function to get current season
   */
  getCurrentSeason() {
    if (this.topSeason) {
      return this.topSeason;
    }
    else {
      let currentSeason = this.currentDate.getFullYear();
      let curretMonth = (this.currentDate.getMonth() + 1);
      if (!(curretMonth >= 4)) {
        currentSeason = currentSeason - 1;
      }
      return currentSeason;
    }
  }

  focusFunction(playerType) {
    this.playerType = playerType;
  }
}
