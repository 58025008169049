import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentDate: any;

  constructor() {
    
   }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentDate.setDate(this.currentDate.getDate());

    this.expirePlayerList(); // Function to expire player list after one day
    this.expireTopLeagues(); //Function to expire the top leagues after one day
  }

  expirePlayerList() {
    var expireDatePlayerList = localStorage.getItem('expireDate');
    expireDatePlayerList = JSON.parse(expireDatePlayerList);
    var finalExpireDatePlayerList = new Date(expireDatePlayerList);

    if(expireDatePlayerList) {
      var Difference_In_Time_PlayerList = Math.abs((finalExpireDatePlayerList).getTime() - this.currentDate.getTime());
      var timeDiffInSecondPlayerList = Math.ceil(Difference_In_Time_PlayerList / 1000); // in second 
      console.log('Time To Expire PlayerList : ' + timeDiffInSecondPlayerList + ' Seconds');
      if (timeDiffInSecondPlayerList <= 0) {
        localStorage.removeItem('dataStorage');
        localStorage.removeItem('expireDate');
      }
    }
  }

  expireTopLeagues() {
    var expireDateTopLeagues = localStorage.getItem('expireDateTopLeagues');
    expireDateTopLeagues = JSON.parse(expireDateTopLeagues)
    var finalExpireDateTopLeagues = new Date(expireDateTopLeagues)

    if(expireDateTopLeagues) {
      var Difference_In_Time_TopLeagues = Math.abs((finalExpireDateTopLeagues).getTime() - this.currentDate.getTime());
      var timeDiffInSecondTopLeagues = Math.ceil(Difference_In_Time_TopLeagues / 1000); // in second 
      console.log('Time To Expire Top Leagues : ' + timeDiffInSecondTopLeagues + ' Seconds');
      if (timeDiffInSecondTopLeagues <= 0) {
        localStorage.removeItem('dataStorage');
        localStorage.removeItem('expireDate');
      }
    }
  }



}
