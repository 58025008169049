import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ApiService, Interceptor } from "./services/rest-api/api.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./module/home/home.component";
import { AboutComponent } from "./module/about/about.component";
import { SharedModule } from "./shared/shared.module";
import { PlayerDashboardComponent } from "./module/player-dashboard/player-dashboard.component";
import { PlayerVsPlayerComponent } from "./module/player-vs-player/player-vs-player.component";
import { SearchPlayerComponent } from "./module/search-player/search-player.component";
import { SearchMetricComponent } from "./module/search-metric/search-metric.component";
import { FaqComponent } from "./module/faq/faq.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { PrivacyPolicyComponent } from './module/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './module/terms-and-conditions/terms-and-conditions.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { StaticPlayerVsPlayerComponent } from './module/static-player-vs-player/static-player-vs-player.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    PlayerDashboardComponent,
    PlayerVsPlayerComponent,
    SearchPlayerComponent,
    SearchMetricComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    StaticPlayerVsPlayerComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    Ng2SmartTableModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    MatDialogModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    AlertModule.forRoot()
  ],
  exports: [SharedModule],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {}
