<header>
    <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
        <a class="navbar-brand text-white font-weight-medium" href="#">Scout<span
                class="text-primary">Charts</span></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a routerLink="/" class="nav-link text-white text-uppercase font-weight-medium">Home</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link text-white text-uppercase font-weight-medium" routerLink="/about">ABOUT US</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link text-white text-uppercase font-weight-medium" routerLink="/faq">FAq</a>
                </li>
            </ul>
        </div>
    </nav>
</header>