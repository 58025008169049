import { Component, OnInit } from "@angular/core";
import { PlayerService } from "../../services/player/player.service";
import { AppApi } from "../../app-api";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [PlayerService]
})
export class HomeComponent implements OnInit {
  showLoader: boolean = false;
  playerList: any = [];
  randomPlayersList = ['ccdc2fff-c938-456d-9750-73e357dde9e4','f67a5c88-75d0-4af6-8535-347430312028','0ca29802-d033-40be-9323-084941724cc8','1979c570-d7d0-4779-ae67-7ccd252572b6','38c216d3-7a8e-4169-a09f-db6bd280b8f2','2c9ffae0-dd03-481b-83f2-1cb5a2440d18','0373011a-b29f-46b3-98b8-39c1e0524cb6','5336ac92-2669-47c1-8120-6702e03e41c4','ab8d942a-80d9-4786-9fff-a565dfb3cec0','6ca7df44-330b-4e38-b8ab-7c1ef18da173','a9fe6a33-3689-4291-9e93-4616302a8c30','4f2e2648-b2f0-454f-952f-f01af080d229','7b325e33-1f62-4bdd-9a19-195108424363'];
  constructor(private playerService: PlayerService, private router: Router) {
  }

  ngOnInit(): void {

    if (!localStorage.getItem("dataStorage")) {
      this.getPlayerList(); //Function to get player list
    } else {
      let expireDate = localStorage.getItem('expireDate');
      if(expireDate){
        expireDate = JSON.parse(expireDate);
        let d = new Date(expireDate);
        let today = new Date();
        today.setDate(today.getDate() + 1);
        if( (today.getDate()) !== d.getDate()){
          localStorage.clear();
          this.getPlayerList(); //Function to get player list
        }
      }else{
        this.playerList = localStorage.getItem("dataStorage");
      }
    }
  }

  /**
   * Function to get player list
   */
  getPlayerList() {
    this.showLoader = true;
    this.playerService.getApiWitoutToken(AppApi.playersList).then((response: any) => {
      this.showLoader = false;
      if (response.success) {
        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        expireDate.setMonth(expireDate.getMonth() - 1);
        localStorage.setItem("expireDate", JSON.stringify(expireDate));

        let dataStorage = JSON.stringify(response.data);
        localStorage.setItem("dataStorage", dataStorage);
        this.playerList = localStorage.getItem("dataStorage");
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }

  /**
   * Function to get player by id
   */
  getPlayerById(type) {
    // if (this.playerList.length > 0) {
      // let playerListCount = JSON.parse(this.playerList).length;
      let playerListCount = this.randomPlayersList.length - 1;
      let random = Math.floor(Math.random() * playerListCount) + 1;
      //let randomPlayerselected = Math.floor(Math.random() * playerListCount - 1 + 1);
      if (type === 1) {
        // this.router.navigateByUrl("/player-dashboard/" + JSON.parse(this.playerList)[randomPlayerselected].id);
        this.router.navigateByUrl("/player-dashboard/" + this.randomPlayersList[random]);
      } else {
        // this.router.navigateByUrl(`/player-vs-player?player1=${JSON.parse(this.playerList)[randomPlayerselected].id}`);
        this.router.navigateByUrl(`/player-vs-player?player1=${this.randomPlayersList[random]}`);
        // this.router.navigateByUrl(`/YqW4q3nLWDV9mwCg?player1=${this.randomPlayersList[random]}`);
      }
    // }else{
    //   this.playerList = localStorage.getItem("dataStorage");
    // }
    
  }

  staticplayer(){
    this.router.navigateByUrl('/player-vs-player')
  }
}
