<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item" routerLink="/"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">FAQ</li>
        </ol>
    </nav>

    <div class="container-md container" id="about">
        <div class="heading mb-4">
            <h2>Frequently Asked Questions</h2>
        </div>
        <p>
            <b>What type of data does scoutcharts provide?</b>
        </p>
        <p>
            Scoutcharts currently provides cumulative season statistics for football players. Stats for teams or player
            stats for single games are currently not available. All data is aggregated on a season level.
        </p>
        <p>
            <b>How many leagues does scoutcharts cover?</b>
        </p>
        <p>
            Currently, scoutcharts provides data for the following 20 men’s leagues: <br />
            1. Premier League (ENG)<br/>
            2. Championship (ENG)<br/>
            3. Primera Division (SPA)<br/>
            4. Segunda Division (SPA)<br/>
            5. Bundesliga 1 (GER)<br/>
            6. Bundesliga 2 (GER)<br/>
            7. Serie A (ITA)<br/>
            8. Serie B (ITA)<br/>
            9. Ligue 1 (FRA)<br/>
            10. Ligue 2 (FRA)<br/>
            11. Eredivisie (NLD)<br/>
            12. Primeira Liga (POR)<br/>
            13. Super Lig (TUR)<br/>
            14. Serie A (BRA)<br/>
            15. Premier League (RUS)<br/>
            16. Super League (CHE)<br/>
            17. Major League Soccer (USA)<br/>
            18. Jupiler Pro League (BEL)<br/>
            19. Allsvenskan (SWE)<br/>
            20. Superligaen (DEN)
        </p>
        <p>
            <b>How many seasons of data are available?</b>
        </p>
        <p>
            Currently stats go back to season 2017 – 2018. 
        </p>
        <p>
            <b>Where does the data come from?</b>
        </p>
        <p>
            The data comes from a third-party reputable provider which supplies scoutcharts with updated player stats daily. 
        </p>

        <p>
            <b>How often is the data updated?</b>
        </p>
        <p>
            The data updates daily. It may take 24 – 48 hrs for players stats to update after they participate in a game. 
        </p>
        <p>
            <b>Can I share the data found on scoutcharts?</b>
        </p>
        <p>
            Yes on the condition that scoutchart is cited as the source of information.
        </p>
        <p>
            <b>What type of metrics are currently available? </b>
        </p>
        <p>
            The metrics available are grouped in three sections – Attacking, Defending, and Passing. All metrics are shown per 90 minutes. <br/>
            Attacking – Goals, Assists, Successful Dribbles, Shots on Target <br/>
            Passing – Total Passes, Key Passes, Pass Accuracy<br/>
            Defedning – Interceptions, Tackles, Duels Won, Blocked Shots<br/>
            In addition, available are also discipline metrics such as yellow cards, red cards, and fouls committed.  
        </p>
        <p>
            <b>How does the player search page work?</b>
        </p>
        <p>
            There are three sections available. The top section shows the player characteristics and basic raw stats. The bubble chart shows the player metrics per 90 and compares the selected player to other players in the same league and same position, relative to the filtering criteria. The bar chart shows percentile rank for the player against other players in the same position, league, and filtering criteria. The league selector is applicable when a player played for two clubs in different leagues during the same season. If they have played for two clubs in the same league their data is aggregated. 
        </p>
        <p>
            <b>How does the advanced search page work? </b>
        </p>
        <p>
            The advanced search page allows you to customize your search and find only the players with requested specifications. You can search across multiple seasons, and leagues. Additionally, you can filter based on age, minutes played, and a few defensive, passing, attacking and discipline metrics. Most metrics are per 90 minutes. Once you find the pool of players, you can sort the result set and you can also click on a player name which will take you their player dashboard page with detailed stats.
        </p>
        <p>
            <b>How does the player v player page work? </b>
        </p>
        <p>
            The player v player function allows you to compare players across multiple defensive, passing and attacking metrics at once. The benchmark is ALL players for the selected season for the Top 5 European Leagues – Premier League, Serie A, Primera Division, League 1 and Bundesliga 1. The comparison is done using a percentile rank. If a player is in the 76th percentile for assists, it means they are better or equal to 76% of players across the Top 5 Leagues for that metric. The percentile calcs are done based on the data per 90. You can also add a second player and compare two players at the same time. 
        </p>
        <p>
            <b>Do you plan to add more functionalities and data?</b>
        </p>
        <p>
            Yes, scoutcharts evolves constantly based on user feedback and demand. For additional features and feedback send an email to scoutcharts@gmail.com or contact us on twitter @scoutcharts.
        </p>
        <p>
            <b>
                How do I contact scoutcharts? 
            </b>
        </p>
        <p>
            Send an email scoutcharts@gmail.com or send a message on twitter @scoutcharts.
        </p>
    </div>
</div>