<app-loader *ngIf="showLoader"></app-loader>
<section id="hero-banner">
    <div class="hero-banner-bg">
        <div class="container">
            <div class="content text-center">
                <h1 class="mx-auto font-weight-medium mb-4">FOOTBALL DATA ANALYTICS <br /> MADE EASY</h1>
                <div class="row">
                    <div class="col-lg-4 features mb-4 mb-lg-0">
                        <div class="no-underline" (click)="getPlayerById(1)"
                            style="color: inherit;cursor:pointer">
                            <div class="icon mb-2">
                                <img src="../../../assets/images/landing-icon-1.png" alt="">
                            </div>
                            <div>
                                <h2>Search by Player</h2>
                                <p class="mb-0">Find players across multiple leagues and compare their performance against others in the same position and league.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 features mb-4 mb-lg-0">
                        <a routerLink="/search-by-metric" class="no-underline" style="color: inherit;">
                            <div class="icon mb-2">
                                <img src="../../../assets/images/landing-icon-2.png" alt="">
                            </div>
                            <div>
                                <h2>Search by Metric</h2>
                                <p class="mb-0">Customize your search based on multiple parameters and produce unique list of players tailored to your requirements.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 features mb-4 mb-lg-0">
                        <!-- (click)="getPlayerById(2)" -->
                        <div (click)=" getPlayerById(2)" class="no-underline"
                            style="color: inherit;cursor:pointer">
                           
                            <div class="icon mb-2">
                                <img src="../../../assets/images/landing-icon-3.png" alt="">
                            </div>
                            <div>
                                <h2>Player v Player</h2>
                                <p class="mb-0">Compare players defensive, attacking and passing performance against each and against the Top 5 Euro Leagues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>