<app-loader *ngIf="showLoader"></app-loader>
<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Players</li>
        </ol>
    </nav>
    <div class="container-fluid">
        <form class="mb-3" [formGroup]="searchPlayerFilterForm">
            <div class="row align-items-center flex-column position-relative">
                <div class="form-group col-lg-6 col-xl-5">
                    <input type="text" (input)="filterPlayers(searchPlayerFilterForm.value.search)" class="form-control"
                        placeholder="Search for a player" formControlName="search" />
                    <div class="spinner-border text-white" role="status" *ngIf="showFilterLoader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div style="color: aqua;" *ngIf="filteredDetail.length > 0">
                    </div>
                    <div class="col-lg-12 p-0 search-player-list">
                        <p *ngFor="let value of filteredDetail" routerLink="/player-dashboard/{{value.id}}">
                            {{value.fullname}} ({{value.nationality}})</p>
                    </div>
                </div>
            </div>
        </form>
        <div class=" mb-4">
            <div class="heading">
                <h2>Top Offensive</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-dark">
                    <thead>
                        <tr>
                            <th scope="col">PLAYERS</th>
                            <th scope="col">CLUB</th>
                            <th scope="col">POSITION</th>
                            <th scope="col">NATIONALITY</th>
                            <th scope="col">DATE OF BIRTH</th>
                            <th scope="col">HEIGHT</th>
                            <th scope="col">WEIGHT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of [].constructor(3)">
                            <td>
                                <a routerLink="/player-dashboard" class="no-underline" style="color: inherit;">
                                    <img src="../../../assets/images/player-img.png" width="40" height="40"
                                        class="mr-2" />
                                    Aaron Ramsey
                                </a>
                            </td>
                            <td>
                                <img src="../../../assets/images/juve.png" width="30" height="30" class="mr-2" />
                                Juventus
                            </td>
                            <td>MidFielder</td>
                            <td>Wales</td>
                            <td>01 Jan, 1987</td>
                            <td>187cm</td>
                            <td>64kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-4">
            <div class="heading">
                <h2>Top Passing</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-dark">
                    <thead>
                        <tr>
                            <th scope="col">PLAYERS</th>
                            <th scope="col">CLUB</th>
                            <th scope="col">POSITION</th>
                            <th scope="col">NATIONALITY</th>
                            <th scope="col">DATE OF BIRTH</th>
                            <th scope="col">HEIGHT</th>
                            <th scope="col">WEIGHT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of [].constructor(3)">
                            <td>
                                <a routerLink="/player-dashboard" class="no-underline" style="color: inherit;">
                                    <img src="../../../assets/images/player-img.png" width="40" height="40"
                                        class="mr-2" />
                                    Aaron Ramsey
                                </a>
                            </td>
                            <td>
                                <img src="../../../assets/images/juve.png" width="30" height="30" class="mr-2" />
                                Juventus
                            </td>
                            <td>MidFielder</td>
                            <td>Wales</td>
                            <td>01 Jan, 1987</td>
                            <td>187cm</td>
                            <td>64kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-4">
            <div class="heading">
                <h2>Top Defensive</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-dark">
                    <thead>
                        <tr>
                            <th scope="col">PLAYERS</th>
                            <th scope="col">CLUB</th>
                            <th scope="col">POSITION</th>
                            <th scope="col">NATIONALITY</th>
                            <th scope="col">DATE OF BIRTH</th>
                            <th scope="col">HEIGHT</th>
                            <th scope="col">WEIGHT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of [].constructor(3)">
                            <td>
                                <a routerLink="/player-dashboard" class="no-underline" style="color: inherit;">
                                    <img src="../../../assets/images/player-img.png" width="40" height="40"
                                        class="mr-2" />
                                    Aaron Ramsey
                                </a>
                            </td>
                            <td>
                                <img src="../../../assets/images/juve.png" width="30" height="30" class="mr-2" />
                                Juventus
                            </td>
                            <td>MidFielder</td>
                            <td>Wales</td>
                            <td>01 Jan, 1987</td>
                            <td>187cm</td>
                            <td>64kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>