import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SeasonDropdownComponent } from './components/season-dropdown/season-dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PlayerSearchComponent } from './components/player-search/player-search.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
  keepSelectedItems: true,
  allowClear: true,
  noAutoComplete: false,
}

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SeasonDropdownComponent, LoaderComponent, PlayerSearchComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSliderModule,
  ],
  exports: [HeaderComponent, FooterComponent, NgxSelectModule, NgMultiSelectDropDownModule, SeasonDropdownComponent, LoaderComponent, PlayerSearchComponent, NgxSliderModule]
})
export class SharedModule { }
