<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item" routerLink="/"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Terms and Conditions</li>
        </ol>
    </nav>

    <div class="container-md container" id="about">
        <div class="heading mb-4">
            <h2>Terms and Conditions</h2>
        </div>
        <p>
            Scoutcharts is offered to you for your personal and/or professional use only, and commercial use via the reselling, republishing, or reprocessing of the content, format, or other information provided is strictly prohibited. You agree to comply with all applicable laws, statutes and regulations in connection with the use of the information obtained via the website. By using the website, you unconditionally agree to be bound by these terms and conditions.  
        </p>
        <p>
            As a condition of using scoutcharts you agree to: 
        </p>
            <ol>
                <li>
                    Not to remove, change or obscure any product identification or notices of proprietary rights on scoutcharts.
                </li>
                <li>
                    Remove any copyright, trade mark, or other intellectual property right notices contained on scoutcharts.
                </li>
                <li>
                    Cite scoutcharts as the source of the information if you choose to share content from the website.
                </li>
            </ol>
        <div class="mt-5">
            <p>
                <b>Disclaimer</b>
            </p>
            <p>
                Scoutcharts does not make any warranties about the completeness, reliability and accuracy of the information on the website. Any action you take upon the information you find on this website, is strictly at your own risk. Scoutcharts will not be liable for any losses and/or damages in connection with the use of our website.
            </p>
        </div>
        <div class="mt-5">
            <p>
                <b>Consent</b>
            </p>
            <p>
                You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using this website and its services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the website and Services.
            </p>
        </div>
        <div class="mt-5">
            <p>
                <b>Contacting us</b>
            </p>
            <p>
                If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to scoutcharts@gmail.com. Should we update, amend or make any changes to this document, those changes will be prominently posted here.
            </p>
        </div>
        <p>
            This document was last updated on March 21, 2021
        </p>
    </div>
</div>