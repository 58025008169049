<div class="dropdown">
    <button class="btn btn-primary dropdown-toggle w-100" type="button">
        <span>Season</span>
        <img src="../../../assets/images/down-arrow.png" class="ml-2" alt="">
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <ul class="pl-0 mb-0">
            <li class="dropdown-item" *ngFor="let season of seasonData.seasons">
                {{ season}}
            </li>
        </ul>
    </div>
</div>