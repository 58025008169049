import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  
  constructor(private titleService:Title) {
    this.titleService.setTitle("Scoutcharts-Terms-And-Conditions");
  }

  ngOnInit(): void {
  }

}
