import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import Swal from "sweetalert2";
import { AppApi } from "../../app-api";
import { PlayerService } from "../../services/player/player.service";
import data from "./searchPlayerData.json";
import { Options } from "@angular-slider/ngx-slider";
import { Router } from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "app-search-metric",
  templateUrl: "./search-metric.component.html",
  styleUrls: ["./search-metric.component.scss"]
})
export class SearchMetricComponent implements OnInit {
  yearOfData = 2019;
  defaultMaxValue = 100;
  searchMetricForm: FormGroup;
  showLoader: boolean = false;
  isFilterFetched: boolean = false;
  metric: any = data as any;
  dropdownSettings: IDropdownSettings;
  selectedLeague = [];
  selectedPositions = [];
  searchMetrixPlayerList = [];
  currentDate: Date;
  currentYear: number;
  maxRange: any = [];
  sliderRangeValues: any = [];
  minValue: number = 14;
  maxValue: number = 40;
  minMinuteValue: number = 500;
  maxMinuteValue: number = 5000;
  optionsAge: Options = {
    floor: 14,
    ceil: 40
  };
  optionsPlayedMinutes: Options = {
    floor: 500,
    ceil: 5000
  };
  settings = {
    hideSubHeader: true,
    actions: false,
    attr: {
      class: "table table-striped table-dark"
    },
    columns: {
      name: {
        title: "PLAYERS",
        filter: false,
        type: "html",
        valuePrepareFunction: (cell, row) => {
          return `
          <a href="/player-dashboard/${row.id}?seasons=${
            this.searchMetricForm.value.seasons
          }" target="_blank" class="no-underline"
          style="color: inherit;">
                <img src="${row.photo}" width="40" height="40" class="mr-2" />
              ${cell}
          </a>`;
        }
      },
      leagueName: {
        title: "LEAGUE",
        filter: false
      },
      clubName: {
        title: "CLUB NAME",
        filter: false
      },
      position: {
        title: "POSITION",
        filter: false
      },
      nationality: {
        title: "NATIONALITY",
        filter: false
      },
      age: {
        title: "AGE",
        filter: false
      },
      minutesPlayed: {
        title: "MINUTES PLAYED",
        filter: false
      },
      rating: {
        title: "RATING",
        filter: false
      },
      weight: {
        title: "PHYSICAL DETAILS",
        filter: false,
        valuePrepareFunction: (cell, row) => {
          return `${row.weight ? row.weight : 0} | ${row.height ? row.height : 0}`;
        }
      }
    }
  };
  constructor(private titleService:Title,private playerService: PlayerService, private router: Router) {
    this.titleService.setTitle("Scoutcharts-Search-By-Metric");
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: true
    };

    for (const position of this.metric.positions) {
      this.selectedPositions.push(position);
    }
    this.selectedLeague.push(this.metric.leagues[0]);
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
  }

  ngOnInit() {
    this.searchMetricForm = new FormGroup({
      seasons: new FormControl(2021, [Validators.required]),
      leagues: new FormControl(this.selectedLeague, [Validators.required]),
      position: new FormControl(this.selectedPositions, [Validators.required]),
      age: new FormControl([this.metric.slider5[0].range1, this.metric.slider5[0].range2], []),
      minutes: new FormControl([this.metric.slider6[0].range1, this.metric.slider6[0].range2], []),
      goals: new FormControl([this.metric.slider1[0].range1, this.metric.slider1[0].range2], []),
      assists: new FormControl([this.metric.slider3[3].range1, this.metric.slider3[3].range2], []),
      nonPenalityGoal: new FormControl([this.metric.slider1[1].range1, this.metric.slider1[1].range2], []),
      shotsOnTarget: new FormControl([this.metric.slider1[2].range1, this.metric.slider1[2].range2], []),
      successDribble: new FormControl([this.metric.slider1[2].range1, this.metric.slider1[2].range2], []),
      tackles: new FormControl([0, 100], []),
      duelWon: new FormControl([0, 100], []),
      interceptions: new FormControl([0, 100], []),
      blockedShots: new FormControl([0, 100], []),
      totalPasses: new FormControl([0, 100], []),
      keyPasses: new FormControl([0, 100], []),
      accuracy: new FormControl([0, 100], []),
      yellowCards: new FormControl([0, 100], []),
      redCards: new FormControl([0, 100], []),
      fouls: new FormControl([0, 100], []),
      panaltiesCommitted: new FormControl([0, 100], [])
    });
    this.getMatrixMaxValue();
  }
  /**
   * Function to flier the search metric
   */
  onSubmitSearchMetric() {
    let formValue = this.searchMetricForm.value;
    let selectedLeague = [];
    let selectedPositions = [];
    for (const league of formValue.leagues) {
      selectedLeague.push(league.id);
    }
    for (const position of formValue.position) {
      selectedPositions.push(position.id);
    }
    const payLoad = {
      leagues: selectedLeague,
      seasons: formValue.seasons,
      position: selectedPositions,
      age: { min: formValue.age[0], max: formValue.age[1] },
      minutes: { min: formValue.minutes[0], max: formValue.minutes[1] },
      goals: { min: formValue.goals[0], max: formValue.goals[1] },
      assists: { min: formValue.assists[0], max: formValue.assists[1] },
      nonPenalityGoal: { min: formValue.nonPenalityGoal[0], max: formValue.nonPenalityGoal[1] },
      shotsOnTarget: { min: formValue.shotsOnTarget[0], max: formValue.shotsOnTarget[1] },
      successDribble: { min: formValue.successDribble[0], max: formValue.successDribble[1] },
      tackles: { min: formValue.tackles[0], max: formValue.tackles[1] },
      duelWon: { min: formValue.duelWon[0], max: formValue.duelWon[1] },
      interceptions: { min: formValue.interceptions[0], max: formValue.interceptions[1] },
      blockedShots: { min: formValue.blockedShots[0], max: formValue.blockedShots[1] },
      totalPasses: { min: formValue.totalPasses[0], max: formValue.totalPasses[1] },
      keyPasses: { min: formValue.keyPasses[0], max: formValue.keyPasses[1] },
      accuracy: { min: formValue.accuracy[0], max: formValue.accuracy[1] },
      yellowCards: { min: formValue.yellowCards[0], max: formValue.yellowCards[1] },
      redCards: { min: formValue.redCards[0], max: formValue.redCards[1] },
      fouls: { min: formValue.fouls[0], max: formValue.fouls[1] },
      panaltiesCommitted: { min: formValue.panaltiesCommitted[0], max: formValue.panaltiesCommitted[1] }
    };
    this.showLoader = true;
    this.playerService.postApiWitoutToken(AppApi.searchMatrix, payLoad).then((response: any) => {
      this.showLoader = false;

      if (response.success && response.data && response.data.length > 0) {
        this.searchMetrixPlayerList = response.data;
      } else {
        this.searchMetrixPlayerList = [];
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }

  /**
   * Function to get matrix max value
   */
  getMatrixMaxValue() {
    let filterMetrics = ['goalsPer90','assistsPer90','shotsOnTargetPer90','successfulDribblesPer90','tacklesPer90','wonDuelsPer90','interceptionsPer90','blocksPer90','totalPassesPer90','nonPenalityGoalPer90','keyPassesPer90','accuracy','red','fouls','panalityCommitted'];
    this.showLoader = true;
    this.playerService.getApiWitoutToken(AppApi.metricsFilterUrl).then((response: any) => {
      this.showLoader = false;
      if (response.success && response.data) {
        for(let k of filterMetrics){
          response.data[0][k] = Math.ceil(+response.data[0][k] || this.defaultMaxValue);
        }
        

        this.maxRange = response.data[0];
        Object.keys(this.maxRange).map(type => {
          const obj = {
            floor: 0,
            ceil: this.maxRange[type],
            step: 0.1
          };
          this.sliderRangeValues[type] = obj;
        });
        this.isFilterFetched = true;
        this.searchMetricForm.patchValue({
          goals: [0, +response.data[0].goalsPer90],
          assists: [0, +response.data[0].assistsPer90],
          nonPenalityGoalPer90: [0, +response.data[0].nonPenalityGoalPer90],
          shotsOnTarget: [0, +response.data[0].shotsOnTargetPer90],
          successDribble: [0, +response.data[0].successfulDribblesPer90],
          tackles: [0, +response.data[0].tacklesPer90],
          duelWon: [0, +response.data[0].wonDuelsPer90],
          interceptions: [0, +response.data[0].interceptionsPer90],
          blockedShots: [0, +response.data[0].blocksPer90],
          totalPasses: [0, +response.data[0].totalPassesPer90],
          keyPasses: [0, +response.data[0].keyPassesPer90],
          accuracy: [0, +response.data[0].accuracy],
          yellowCards: [0, +response.data[0].yellow],
          redCards: [0, +response.data[0].red],
          fouls: [0, +response.data[0].fouls],
          panaltiesCommitted: [0, +response.data[0].panalityCommitted]
        });
      } else {
        this.searchMetrixPlayerList = [];
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
      this.onSubmitSearchMetric();
    });
  }
}
