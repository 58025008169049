import { Injectable } from '@angular/core';
import { ApiService } from '../rest-api/api.service'
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private apiService: ApiService) { }
  /**
 * Generic Get Method
 * @param apiUrl api url
 */
  public getApi(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }

  /**
   * Generic Post Method
   * @param apiUrl api url
   * @param data request data
   */
  public postApi(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }

  /**
   * Generic Post Method
   * @param apiUrl api url
   * @param data request data
   */
  public postApiWitoutToken(apiUrl: string, data: any) {
    return this.apiService.postApiWitoutToken(apiUrl, data)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }

  /**
  * Generic Get Method
  * @param apiUrl api url
  */
  public getApiWitoutToken(apiUrl: string) {
    return this.apiService.getApiWitoutToken(apiUrl)
      .toPromise().then((res: any) => {
        return res;
      }).catch(error => {
        let badRequestError = {
          success: false,
          data: null,
          message: "Server Error, Something went wrong!"
        };
        return badRequestError;
      });
  }


}
