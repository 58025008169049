import { Component, Input, OnInit } from '@angular/core';
import data from './seasonData.json';

@Component({
  selector: 'app-season-dropdown',
  templateUrl: './season-dropdown.component.html',
  styleUrls: ['./season-dropdown.component.scss']
})
export class SeasonDropdownComponent implements OnInit {

  seasonData:any=(data as any);
  @Input()
  class:string;

  constructor() { }

  ngOnInit(): void {
  }

}
