<app-loader *ngIf="loader"></app-loader>
<div class="content static-player-vs-player">

    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Player vs Player</li>
        </ol>
    </nav>
    <div class="alert_msg" *ngIf="alertbx">
        <alert type="success">
            <span class="crose_button" (click)="cross()" style="position: absolute;
            right: 15px;
            top: 2px;">X</span>
            <strong>Thank you</strong> for your interest! We’re currently updating the tool and working hard to put the
            finishing touches. Things are going well and it should be ready very soon. Our team will be reaching out to
            you
            with
            updates.
        </alert>
    </div>
    <div class="container-md container" id="static-player-vs-player">
        <div class="heading mb-4 text-center">
            <h2>Player Comparison Tool
            </h2>
        </div>
        <p class="text-center">
            We are supercharging our website with an interactive radar tool! Compare over 10,000 players from 20
            leagues, and four historical seasons!
            Sign up below to enjoy FREE access to our interactive comparison tool!
        </p>

        <ol class="row m-0 p-md-0 justify-content-between ol-list">
            <li>
                Single Player Comparison vs a Top 5 Leagues Player Benchmark
            </li>
            <li>
                Player vs Player Comparison Across Multiple Seasons
            </li>
            <li>
                See Players Progress by Comparing Their Current vs Historical Seasons
            </li>
            <li>
                Control the metrics on the Radar, Seasons, Player Positions Compared Against
            </li>
        </ol>

        <!-- cta slider -->
        <!-- <div class="row my-5 access_page ">
            <div class="col-md-6 col-sm-7 access_page--l text-center ">
                <h2 class="mb-4">Sign Up to Get Access </h2>
                <button type="button" class="btn btn-primary w-200" (click)="openDialog(template)">Click Here</button>
            </div>
            <div class="col-sm-4 offset-md-1 access_page--slider">
                <carousel>
                    <slide>
                        <img src="../../../assets/images/slide1.png" alt="first slide"
                            style="display: block; width: 100%;">
                    </slide>
                    <slide>
                        <img src="../../../assets/images/slide2.png" alt="second slide"
                            style="display: block; width: 100%;">
                    </slide>
                    <slide>
                        <img src="../../../assets/images/slide3.png" alt="third slide"
                            style="display: block; width: 100%;">
                    </slide>
                </carousel>

            </div>
        </div> -->
        <div class="row my-5 access_page ">
            <div class="col-md-4 col-sm-7 access_page--l text-center ">
                <h2 class="mb-4">Sign Up to Get Access </h2>
                <button type="button" class="btn btn-primary w-200" (click)="openDialog(template)">Click Here</button>
            </div>
            <div class="col-md-8 col-sm-5 access_page--slider">
                <carousel>
                    <slide>
                        <img src="../../../assets/images/slide1.png" alt="first slide"
                            style="display: block; width: 100%;">
                    </slide>
                    <slide>
                        <img src="../../../assets/images/slide2.png" alt="second slide"
                            style="display: block; width: 100%;">
                    </slide>
                    <slide>
                        <img src="../../../assets/images/slide3.png" alt="third slide"
                            style="display: block; width: 100%;">
                    </slide>
                </carousel>

            </div>
        </div>

    </div>
    <!--  -->
</div>
<!--  -->

<!--  -->
<ng-template #template>
    <div class="dialog-outer">
        <div class="dialog-bx position-relative">
            <!--  -->
            <!-- modalRef?.hide() -->
            <figure class="dialog-close d-flex justify-content-center align-items-center" (click)="closedialog()">
                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                    <path
                        d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                </svg>
            </figure>
            <h2>Access Page</h2>
            <p>Please fill out the below form to access page</p>
            <form [formGroup]="accessForm" (ngSubmit)="onSubmit()">
                <div>
                    <label for="">Email</label>
                    <input placeholder="Email" formControlName="email">
                    <div *ngIf="submitted && fc.email.errors">
                        <p class="error" *ngIf="fc.email.errors.required">Enter email address</p>
                        <p class="error" *ngIf="fc.email.errors.email">Enter valid email address</p>
                    </div>
                </div>
                <div class="mb-4">
                    <label for="">I represent</label>
                    <select formControlName="interest">
                        <option value="Football club">Football club</option>
                        <option value="Media">Media</option>
                        <option value="antasy Football Fan">Fantasy Football Fan</option>
                        <option value="Football Fan">Football Fan</option>
                        <option value="Agency">Agency</option>
                        <option value="Other">Other</option>
                    </select>
                    <div *ngIf="submitted && fc.interest.errors">
                        <p class="error" *ngIf="fc.interest.errors.required">Select i represent category</p>
                    </div>
                </div>
                <button class="btn btn-primary w-200 m-auto d-block">
                    Submit
                </button>
            </form>
        </div>
    </div>
</ng-template>
