<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item" routerLink="/"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">About Us</li>
        </ol>
    </nav>

    <div class="container-md container" id="about">
        <div class="heading mb-4">
            <h2>About Us</h2>
        </div>
        <p>
            Hello Friends and Welcome to Scoutcharts! 
        </p>
        <p>
            If you’ve made it to this page, I can only assume football is as big a passion for you as it is for me. While other interests in life have come and gone, my love for the game has remained as strong as ever. And while I never made it as a pro footballer,I always knew I wanted to be a part of that world and make my contribution to the field, one way or another. And I hope Scoutcharts is the first step of making that contribution.
        </p>
        <p>
            I have spent number of years professionally in Data Analytics, helping companies tell better stories through their data and make smarter business decisions. What’s clear is that data by itself does not mean much unless it is put in the right context and presented the right way. But the true insight comes when the end user can interact with the data live, slice and dice, filter and look at it from different angles, or what is called self-service analytics. Same applies to football. There is an abundance of stats out there, and nicely done visuals. But I often find that data or beautiful graphs to be static or provide very little interaction and flexibility for the user. So, I just end up downloading the dataset and creating my own reporting and visualizations. While there are great football analytics products out there with great functionalities, these typically cost a lot and are geared towards professional clubs and organizations. It is hard to find a true interactive tool which is both accessible and affordable to everyone passionate about the game.
        </p>
        <p>
            This is how the idea for scoutcharts was born. Scoutcharts is an accessible and easyto use analytics platform for comparing football players’ performance and stats through interactive visualizations. It is a self-service tool, with dynamic data updated daily. It allows the user to slice and dice the data and compare multiple players, see how a player performs against a pool of players in the same position, orentire leagues. The site is built for the broad football community – football professionals, scouts, media, bloggers, fantasy football fans, super fans, or just enthusiast checking out their favorite players. 
        </p>
        <p>
            I hope you like it. I plan to keep the site evolving with additional features, more detailed data, and more interactive visualizations. But any new feature will be based on your feedback, so please write to me at scoutcharts@gmail.com or twitter. 
        </p>
        <div style="margin-bottom:2rem">
            Cheers!
            <div>
                Tihomir Cheresharski, 
            </div>
            <div>
                ScoutCharts Founder
            </div>
        </div>
        
    </div>
</div>