import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./module/about/about.component";
import { TermsAndConditionsComponent } from "./module/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./module/privacy-policy/privacy-policy.component";
import { FaqComponent } from "./module/faq/faq.component";
import { HomeComponent } from "./module/home/home.component";
import { PlayerDashboardComponent } from "./module/player-dashboard/player-dashboard.component";
import { PlayerVsPlayerComponent } from "./module/player-vs-player/player-vs-player.component";
import { SearchMetricComponent } from "./module/search-metric/search-metric.component";
import { StaticPlayerVsPlayerComponent } from "./module/static-player-vs-player/static-player-vs-player.component";
// import { SearchPlayerComponent } from "./module/search-player/search-player.component";


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "player-dashboard/:id", component: PlayerDashboardComponent },
  { path: "player-vs-player", component: PlayerVsPlayerComponent },
  { path: "YqW4q3nLWDV9mwCg", component: StaticPlayerVsPlayerComponent },
  //{path: 'search-by-player', component:SearchPlayerComponent},
  { path: "search-by-metric", component: SearchMetricComponent },
  { path: "faq", component: FaqComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
