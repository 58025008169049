<app-loader *ngIf="showLoader"></app-loader>
<div class="content" id="player-dashboard">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item"><a routerLink="">Home</a></li>
            <!-- <li class="breadcrumb-item"><a routerLink="/search-by-player">Player</a></li> -->
            <li class="breadcrumb-item active">Player Dashboard</li>
        </ol>
    </nav>
    <div class="position-relative container-fluid">
        <div class="position-absolute w-100 h-100 data-loader" *ngIf="showLoaderDetails">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <form class="mb-3" [formGroup]="searchPlayerFilterForm">
            <div class="row align-items-center flex-column position-relative">
                <div class="form-group col-lg-6 col-xl-5">
                    <input type="text" (input)="filterPlayers(searchPlayerFilterForm.value.search)"
                        class="form-control search-for-player" placeholder="Search for a player"
                        formControlName="search" />
                    <div class="spinner-border text-white" role="status" *ngIf="showFilterLoader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div style="color: aqua;" *ngIf="filteredDetail.length > 0">
                    </div>
                    <div class="col-lg-12 p-0 search-player-list">
                        <p *ngFor="let value of filteredDetail" routerLink="/player-dashboard/{{value.id}}">
                            {{value.fullname}} ({{value.nationality}})</p>
                    </div>
                </div>
            </div>
        </form>
        <div class="player-dashborad mb-4">
            <div class="row">
                <div class="col-xl-12 mb-4 d-flex flex-wrap justify-content-between align-items-center">
                    <div class="heading">
                        <h2 class="mb-0">Player Dashboard</h2>
                    </div>
                    <form [formGroup]="filterPlayerForm" class="my-2 my-sm-0" style="max-width: 150px;width: 100%;">
                        <ngx-select formControlName="seasons" [items]="metric.seasons" placeholder="Select Season"
                            (select)="onChangeSeason($event)">
                        </ngx-select>
                    </form>
                </div>
            </div>
            <div class="bg-dark-50">
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="col-xl-2 col-md-6 divider mb-3 mb-xl-0">
                        <div class="profile text-center">
                            <img *ngIf="playerDetails && playerDetails.personal_info && playerDetails.personal_info.photo; else imageNotFound"
                                src="{{playerDetails.personal_info.photo}}" class="rounded-circle" width="100"
                                height="100" alt="">
                            <ng-template #imageNotFound>
                                <img src="../../../assets/images/player-img.png" width="100" height="100" alt="">
                            </ng-template>

                            <h2 class="mt-2">
                                {{playerDetails && playerDetails.personal_info ? playerDetails.personal_info.name :
                                ''}}
                            </h2>
                            <div class="club d-flex align-items-center justify-content-center">
                                <img height="60"
                                    src="{{playerDetails && playerDetails.personal_info && playerDetails.personal_info.team ? playerDetails.personal_info.team.logo : ''}}"
                                    alt="" />
                            </div>
                            <div *ngIf="playerDetails && playerDetails.personal_info &&
                            playerDetails.personal_info.league" class="club d-flex mt-2 align-items-center justify-content-center">
                                <span class="ml-2">Season {{playerDetails && playerDetails.personal_info &&
                                    playerDetails.personal_info.league ?
                                    playerDetails.personal_info.league.season : ''}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6 divider mb-3 mb-xl-0">
                        <table class="w-100">
                            <tr>
                                <th>POSITION</th>
                                <ng-container
                                    *ngIf="playerDetails && playerDetails.personal_info && playerDetails.personal_info.position == 'Attacker'; then static; else dynamic">
                                </ng-container>

                                <ng-template #static>
                                    <td>
                                        Forward
                                    </td>
                                </ng-template>
                                <ng-template #dynamic>
                                    <td>
                                        {{ playerDetails && playerDetails.personal_info && playerDetails.personal_info.position ? playerDetails.personal_info.position : 'N/A'}}
                                    </td>
                                </ng-template>
                            </tr>
                            <tr>
                                <th> League</th>
                                <td>{{playerDetails && playerDetails.personal_info && playerDetails.personal_info.league ?
                                    playerDetails.personal_info.league.name
                                    : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>Team</th>
                                <td>{{playerDetails && playerDetails.personal_info && playerDetails.personal_info.team ?
                                    playerDetails.personal_info.team.name : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>NATIONALITY</th>
                                <td>{{playerDetails && playerDetails.personal_info ?
                                    playerDetails.personal_info.nationality : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>DATE OF BIRTH </th>
                                <td>{{playerDetails && playerDetails.personal_info &&
                                    playerDetails.personal_info.birth
                                    ? playerDetails.personal_info.birth.date : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>AGE</th>
                                <td>{{playerDetails && playerDetails.personal_info &&
                                    playerDetails.personal_info.age
                                    ? playerDetails.personal_info.age : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>HEIGHT</th>
                                <td>{{playerDetails && playerDetails.personal_info ?
                                    playerDetails.personal_info.height?playerDetails.personal_info.height:'N/A'
                                    : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <th>WEIGHT</th>
                                <td>{{playerDetails && playerDetails.personal_info ?
                                    playerDetails.personal_info.weight?playerDetails.personal_info.weight
                                    : 'N/A':'N/A'}}
                                </td>
                            </tr>
                             <!-- <tr>
                                <th>PREFERRED FOOT</th>
                                <td>Right foot</td>
                             </tr> -->
                        </table>
                    </div>
                    <div class="col-xl-3 col-md-6 divider">
                        <table class="w-100">
                            <tr>
                                <th>GAMES PLAYED</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.gamesPlayed ?
                                    playerDetails.statistics.gamesPlayed :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>MINUTES</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.minutes ? playerDetails.statistics.minutes :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>YELLOW CARD</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.yellowCard ?
                                    playerDetails.statistics.yellowCard :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>RED CARD FOULS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.redCardFouls ?
                                    playerDetails.statistics.redCardFouls
                                    : 0}}
                                </td>
                            </tr>
                            <tr>
                                <th>FOULS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.fouls ? playerDetails.statistics.fouls :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>GOALS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.goals ? playerDetails.statistics.goals :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>ASSISTS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.assists ? playerDetails.statistics.assists :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>SHOTS ON</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.shots ? playerDetails.statistics.shots :
                                    0}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-xl-3 col-md-6 divider">
                        <table class="w-100">
                            <tr>
                                <th>SUCCESSFUL DRIBBLES</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.successDribbles ?
                                    playerDetails.statistics.successDribbles : 0}}
                                </td>
                            </tr>
                            <tr>
                                <th>TACKLES</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.totalTackles ?
                                    playerDetails.statistics.totalTackles
                                    : 0}}
                                </td>
                            </tr>
                            <tr>
                                <th>DUELS WON</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.duelsWon ? playerDetails.statistics.duelsWon : 0}}
                                </td>
                            </tr>
                            <tr>
                                <th>INTERCEPTIONS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.interceptions ?
                                    playerDetails.statistics.interceptions
                                    : 0}}
                                </td>
                            </tr>
                            <tr>
                                <th>BLOCKED SHOTS</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.tacklesBlocks ?
                                    playerDetails.statistics.tacklesBlocks
                                    : 0}}</td>
                            </tr>
                            <tr>
                                <th>TOTAL PASSES</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.totalPasses ?
                                    playerDetails.statistics.totalPasses :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>KEY PASSES</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.keyPasses ? playerDetails.statistics.keyPasses
                                    :
                                    0}}
                                </td>
                            </tr>
                            <tr>
                                <th>PASS ACCURACY (per game)</th>
                                <td>{{playerDetails && playerDetails.statistics && playerDetails.statistics.accuracy ? playerDetails.statistics.accuracy
                                    :
                                    0}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="playerDetails && playerDetails.personal_info &&
    playerDetails.personal_info.league ? '': 'd-none'" class="position-relative container-fluid">
        <div class="position-absolute w-100 h-100 data-loader" *ngIf="showLoaderGraphs">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-xl-4 mb-4 order-2 order-xl-1">
                <div class="heading mb-4">
                    <h2 class="mb-0">Player Metrics</h2>
                </div>
                <div class="bar-chart">
                    <div id="matric-chart">
                        <div id="matric-chart-label">Percentile Rank</div>
                    </div>
                    <!-- <img src="../../../assets/images/player-metrices.png" class="w-100" /> -->
                </div>
            </div>
            <div class="col-xl-8 mb-4 order-1 order-xl-2">
                <form [formGroup]="searchGraphFilterForm" (ngSubmit)="onSubmitSearchFilter()">
                    <div class="d-flex mb-3 align-items-lg-center flex-column flex-lg-row">
                        <div class="d-flex flex-grow-1 justify-content-lg-end flex-column flex-md-row">
                            <div class="multiselect-drop-down mr-md-3 flex-grow-1 mb-3 mb-md-0">
                                <ng-multiselect-dropdown placeholder="" class="btn-xs" [settings]="dropdownSettings"
                                    formControlName="minutes" [data]="playerDashboard.seasons"
                                    [(ngModel)]="selectedSeasons">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="dropdown mr-md-3 flex-grow-1 mb-3 mb-md-0">
                                <ngx-select [items]="leagueList" formControlName="leagueId"
                                    placeholder="Select Competition">
                                </ngx-select>
                            </div>
                            <div class="dropdown mr-md-3 flex-grow-1 mb-3 mb-md-0">
                                <ngx-select [items]="playerDashboard.Competetion" formControlName="category"
                                    placeholder="Select Category" (select)="onChangeCategory($event)">
                                </ngx-select>

                            </div>
                            <div class="dropdown flex-grow-1 no-data mr-md-3 mb-3 mb-md-0">
                                <ngx-select [items]="metricList" formControlName="metric" placeholder="Select Metric">
                                </ngx-select>
                            </div>
                            <div class="flex-grow-1">
                                <button type="submit" class="btn btn-primary w-100"
                                    style="height: 46px;">Search</button>
                            </div>

                        </div>
                    </div>
                    <div class="bubble-chart">
                       
                        <div id="container-bubble"></div>
                        <div style="position: absolute; right: 25px; bottom: 0px; font-size: 14px;">Powered by Scout<span style="color:red">Charts</span></div>
                        <!-- <img src="../../../assets/images/filter.png" class="w-100" />  -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>