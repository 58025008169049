import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../../services/player/player.service';
import { AppApi } from '../../app-api';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-search-player',
  templateUrl: './search-player.component.html',
  styleUrls: ['./search-player.component.scss'],
  providers: [PlayerService]
})
export class SearchPlayerComponent implements OnInit {

  searchPlayerFilterForm: FormGroup;
  showLoader: boolean = false;
  showFilterLoader: boolean = false;
  playerList:any = [];
  filteredDetail: any = [];
  

  constructor(private titleService:Title,private playerService: PlayerService) {
    this.titleService.setTitle("Scoutcharts-Player-Search");
  }
  
  ngOnInit(): void {
    this.searchPlayerFilterForm = new FormGroup({
      search: new FormControl('', [Validators.required]),
    });
    if (!localStorage.getItem('dataStorage')) {
      this.getPlayerList(); //Function to get player list
    } else {
      this.playerList = localStorage.getItem('dataStorage');
    }

  }

  /**
   * Function to get player list
   */
  getPlayerList() {
    this.showLoader = true;
    this.playerService.getApiWitoutToken(AppApi.playersList).then((response: any) => {
      this.showLoader = false;
      if (response.success) {
        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        localStorage.setItem('expireDate', JSON.stringify(expireDate))
        
        let dataStorage = JSON.stringify(response.data);
        localStorage.setItem('dataStorage', dataStorage);
        this.playerList = localStorage.getItem('dataStorage');
      } else {
        Swal.fire({
          title: response.message,
          icon: 'error',
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        })
      }
    })
  }

  /**
   * Function to filter players
   * @param value 
   */
  filterPlayers(value) {
    if (value && value.length >= 3) {
      this.filteredDetail = this.playerList ? JSON.parse(this.playerList).filter(searchFilter => searchFilter.fullname.toLowerCase().includes(value.toLowerCase()) || searchFilter.lastname.toLowerCase().includes(value.toLowerCase())) : [];
    } else {
      this.filteredDetail = [];
    }
  }
}