import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import Swal from "sweetalert2";
import { AppApi } from "../../app-api";
import { PlayerService } from "../../services/player/player.service";
import data from "./playerDashboardData.json";
import dataPlayer from "./../search-metric/searchPlayerData.json";
import {Title} from "@angular/platform-browser";
highchartsMore(Highcharts);
import $ from "jquery";

@Component({
  selector: "app-player-dashboard",
  templateUrl: "./player-dashboard.component.html",
  styleUrls: ["./player-dashboard.component.scss"]
})
export class PlayerDashboardComponent implements OnInit {
  searchGraphFilterForm: FormGroup;
  playerDashboard: any = data as any;
  metric: any = dataPlayer as any;
  playerDetails: any;
  rankMatrixKey = [
    "Goals",
    "Assists",
    "Shots on target",
    "Successful dribbles",
    "Total passes",
    "Key passes",
    "Total tackles",
    "Blocked Shots",
    "Interceptions",
    "Total duels",
    "Won duels"
  ];
  rankMatrixValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  metricGraph;
  showLoader: boolean = false;
  dropdownSettings: IDropdownSettings;
  metricList = [];
  leagueList = [];
  highcharts = Highcharts;
  playerId;
  searchPlayerFilterForm: FormGroup;
  filterPlayerForm: FormGroup;
  showFilterLoader: boolean = false;
  playerList: string = "";
  filteredDetail: any = [];
  selectedSeasons = [];
  selectedMetricsSeasons: any;
  selectedMatrixName = "Goals Per 90";
  patchLeagueId: boolean = true;
  showLoaderDetails: boolean = false;
  showLoaderGraphs: boolean = false;
  defaultSeason:Number = 2021;
  public metricsChartOptions: any = {
    chart: {
      type: "bar",
      height: "600px"
    },
    xAxis: {
      categories: [],
      title: {
        text: ""
      }
    },
    yAxis: {
      max: 100,
      title: {
        text: "Percentile Rank",
        // align: "start",
        x:-70
      },
      // labels: {
      //   overflow: "justify"
      // }
    },
    tooltip: {
      headerFormat: "",
      valueSuffix: " %"
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
      showInLegend: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        showInLegend: false,
        name: "",
        data: []
      }
    ]
  };

  public bubbleChartOptions: any = {
    chart: {
      type: "bubble",
      height: "600px"
    },
    xAxis: {
      min: 0,
      categories: [],
      tickmarkPlacement: 'on',
      title: {
        text: this.selectedMatrixName
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "Playing Time"
      },
      labels: {
        overflow: "justify"
      }
    },
    tooltip: {
      useHTML: true,
      headerFormat: "<table>",
      pointFormat:
        "<tr><th>Player Name:</th><td>{point.player}</td></tr>" +
        "<tr><th>Team:</th><td>{point.team}</td></tr>" +
        "<tr><th>{point.selectedMetricName} :</th><td>{point.x}</td></tr>" +
        "<tr><th>Minutes Played:</th><td>{point.y}</td></tr>",
      footerFormat: "</table>",
      followPointer: true
    },
    
    plotOptions: {
      bubble: {
        minSize: 15,
        maxSize: 15
      },
      series: {
        pointPlacement: 'on'
      },
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
      showInLegend: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        data: []
      }
    ]
  };
  metricObject: any;
  getSelectedSeason: number;

  constructor(private titleService:Title,private playerService: PlayerService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.titleService.setTitle("Scoutcharts-Player-Dashboard");
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: true
    };
  }

  ngOnInit() {
    this.searchPlayerFilterForm = new FormGroup({
      search: new FormControl("", [Validators.required])
    });
    if (!localStorage.getItem("dataStorage")) {
      this.getPlayerList(); //Function to get player list
    } else {
      this.playerList = localStorage.getItem("dataStorage");
    }

    this.highcharts.chart("matric-chart", this.metricsChartOptions);
    this.highcharts.chart("container-bubble", this.bubbleChartOptions);

    this.searchGraphFilterForm = new FormGroup({
      minutes: new FormControl("", []),
      leagueId: new FormControl("", []),
      category: new FormControl(1, []),
      metric: new FormControl(1, [])
    });
    this.onChangeCategory(1); //load the default matrix
    for (const season of this.playerDashboard.seasons) {
      if (season.id != 0) {
        this.selectedSeasons.push(season);
      }
    }

    this.filterPlayerForm = new FormGroup({
      seasons: new FormControl(null, [Validators.required])
    });

    const getSelectedSeason = this.activatedRoute.snapshot.queryParamMap.get("seasons");
    // if (getSelectedSeason) {
      this.getSelectedSeason = Number(getSelectedSeason);
      this.filterPlayerForm.patchValue({
        seasons: this.getSelectedSeason? this.getSelectedSeason : this.defaultSeason
      });
    // }

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.playerId = params.id;
        this.getPlayerDetail(params.id);
      }
    });

  }
  
  /**
   * Function to filter the metrix on change category
   * @param value
   */
  onChangeCategory(value) {
    this.metricList = [];
    if (value) {
      this.metricList = this.playerDashboard.CompetetionOptions.filter(e => e.type == value);
      this.searchGraphFilterForm.patchValue({
        metric: this.metricList.length > 0 ? this.metricList[0].id : ""
      });
    }
  }

  /**
   * Function to get the player details by PlayerId
   * @param playerId
   */
  getPlayerDetail(playerId) {
    let payLoad: any = { id: playerId, leagueId: null };
    if (this.patchLeagueId == false) {
      payLoad = { id: playerId, leagueId: this.searchGraphFilterForm.value.leagueId };
    }
    if (this.getSelectedSeason) {
      payLoad.season = this.getSelectedSeason;
    }else{
      payLoad.season = this.defaultSeason
    }
    this.showLoaderDetails = true;
    this.playerService.postApiWitoutToken(AppApi.playerDetail, payLoad).then((response: any) => {
      this.searchPlayerFilterForm.reset();
      this.showLoaderDetails = false;
      if (response.success && response.data) {
        this.playerDetails = response.data;
        this.leagueList = response.data.leagues;
        if (response.data.leagues && response.data.leagues.length > 0) {
          let selectedSeasion = [];
          for (const seasons of this.selectedSeasons) {
            selectedSeasion.push(seasons.id);
          }
          if (this.patchLeagueId) {
            this.getMetricGraph(
              response.data.personal_info.id,
              response.data.personal_info.leagueId,
              response.data.personal_info.leagueId,
              selectedSeasion
            );
            this.searchGraphFilterForm.patchValue({
              leagueId: response.data.personal_info ? response.data.personal_info.leagueId : ""
            });
          } else {
            this.getMetricGraph(
              response.data.personal_info.id,
              this.searchGraphFilterForm.value.leagueId,
              response.data.personal_info.leagueId,
              selectedSeasion
            );
          }
        } else {
          this.bubbleChartOptions.series = [{ data: [] }];
          this.highcharts.chart("container-bubble", this.bubbleChartOptions);
          this.metricsChartOptions.series = [
            {
              showInLegend: false,
              name: "",
              data: []
            }
          ];
          this.highcharts.chart("matric-chart", this.metricsChartOptions);
          Swal.fire({
            title: 'No matrics data found for the selected season.',
            icon: "error",
            timer: 5000,
            position: "top-right",
            toast: true,
            showCancelButton: false,
            showConfirmButton: false
          });
        }
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }

  /**
   * Function to get the graph
   * @param playerId
   * @param playerDetails
   * @param minutes
   */
  getMetricGraph(playerId = null, currentPlayerLeagueId = null, leagueId = null, minutes) {
    this.metricObject = {
      id: playerId,
      currentPlayerLeagueId: currentPlayerLeagueId,
      leagueId: leagueId,
      minutes: minutes
    };
    if (this.getSelectedSeason) {
      this.metricObject.season = this.getSelectedSeason;
    }
    this.showLoaderGraphs = true;
    this.playerService.postApiWitoutToken(AppApi.matricList, this.metricObject).then((response: any) => {
      this.showLoaderGraphs = false;
      if (response.success) {
        this.rankMatrixKey = response.data && response.data.rankGraph ? response.data.rankGraph.map(e => e.name) : [];
        this.rankMatrixValue = response.data.rankGraph ? response.data.rankGraph.map(e => parseFloat(e.percentile)) : [];
        this.metricGraph = response.data.metricGraph;
        this.metricBubbleGraph(response.data.metricGraph);
        this.metricsChartOptions.xAxis = {
          categories: this.rankMatrixKey
        };
        this.metricsChartOptions.series = [
          {
            showInLegend: false,
            name: "",
            data: this.rankMatrixValue
          }
        ];
        this.highcharts.chart("matric-chart", this.metricsChartOptions);
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
        this.highcharts.chart("container-bubble", this.bubbleChartOptions);
      }
    });
  }

  /**
   * Create natrix graph
   * @param metricDetail
   */
  metricBubbleGraph(metricDetail) {
    let bubbleMetricChart = [];
    if (metricDetail && metricDetail.current && metricDetail.others) {
      let selectedMetric = this.metricList.filter(e => e.id == this.searchGraphFilterForm.value.metric);
      let yAxisMinValue = metricDetail.others.length?metricDetail.others[0].playingTime:0;
      let xAxisMinValue = 0, xAxisMaxValue = 0;
      for (let i=0; i<metricDetail.others.length;i++) {
        let others  = metricDetail.others[i];
        let xAxisValue = Number(others[selectedMetric[0].apiKey]);
        if(i === 0){
          xAxisMinValue = metricDetail.others.length?xAxisValue:0;
          xAxisMaxValue = xAxisMinValue;
        }else{
          
          xAxisMinValue = xAxisValue < xAxisMinValue ? xAxisValue : xAxisMinValue;
          xAxisMaxValue = xAxisValue > xAxisMaxValue ? xAxisValue : xAxisMaxValue;
        }
        yAxisMinValue = others.playingTime < yAxisMinValue ? others.playingTime : yAxisMinValue;
        let playerName = "";
        let playerId = "";
        let playerData = this.playerList?JSON.parse(this.playerList).filter(searchFilter => searchFilter.id === others.playerId):[];
        if (playerData.length > 0) {
          playerName = playerData[0].fullname;
          playerId = playerData[0].id;
        }
        if(others[selectedMetric[0].apiKey]){
          let metricValue = parseFloat(others[selectedMetric[0].apiKey]);
          if(selectedMetric[0].name.includes('%')){
            metricValue = Math.round(others[selectedMetric[0].apiKey])
          }
           bubbleMetricChart.push({
          x: metricValue,
          y: others.playingTime,
          z: 2,
          color: "blue",
          team: others.team.name,
          player: playerName,
          playerId: playerId,
          selectedMetricName: selectedMetric[0].graphName
        });
        }
       
      }

      let currentPlayerName = "";
      let currentPlayerId = "";
      let currentPlayerData = this.playerList? JSON.parse(this.playerList).filter(
        searchFilter => searchFilter.id === metricDetail.current.playerId
      ):[];
      if (currentPlayerData.length > 0) {
        currentPlayerName = currentPlayerData[0].fullname;
        currentPlayerId = currentPlayerData[0].id;
        yAxisMinValue = metricDetail.current.playingTime < yAxisMinValue ? metricDetail.current.playingTime : yAxisMinValue;
        let xvalue = Number(metricDetail.current[selectedMetric[0].apiKey]);
        xAxisMinValue = xvalue < xAxisMinValue ? xvalue : xAxisMinValue;
        xAxisMaxValue = Number(metricDetail.current[selectedMetric[0].apiKey]) > Number(xAxisMaxValue) ? Number(metricDetail.current[selectedMetric[0].apiKey]) : Number(xAxisMaxValue);
      }
      bubbleMetricChart.push({
        x: parseFloat(metricDetail.current[selectedMetric[0].apiKey]),
        y: metricDetail.current.playingTime,
        z: 2,
        color: "#ff0000",
        team: metricDetail.current.team.name,
        player: currentPlayerName,
        playerId: currentPlayerId,
        selectedMetricName: selectedMetric[0].graphName
      });
      if(yAxisMinValue > 500){
        yAxisMinValue = yAxisMinValue - 250;
      }
      

      // tickInterval:5,
      this.bubbleChartOptions.yAxis.min = yAxisMinValue;
      this.bubbleChartOptions.xAxis.min = Number(xAxisMinValue)//.toFixed(1);
      this.bubbleChartOptions.xAxis.tickInterval = Number((xAxisMaxValue/5).toFixed(1));
      this.bubbleChartOptions.series = [{ data: bubbleMetricChart }];
      this.highcharts.chart("container-bubble", this.bubbleChartOptions);
      let selectedMetricName = selectedMetric[0].graphName;
      setTimeout(function () {
        $("#container-bubble .highcharts-xaxis .highcharts-axis-title").text(selectedMetricName);
      }, 200);
    }
  }

  /**
   * Function to get player list
   */
  getPlayerList() {
    this.showLoader = true;
    this.playerService.getApiWitoutToken(AppApi.playersList).then((response: any) => {
      this.showLoader = false;
      if (response.success) {
        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        localStorage.setItem("expireDate", JSON.stringify(expireDate));

        let dataStorage = JSON.stringify(response.data);
        localStorage.setItem("dataStorage", dataStorage);
        this.playerList = localStorage.getItem("dataStorage");
      } else {
        Swal.fire({
          title: response.message,
          icon: "error",
          timer: 5000,
          position: "top-right",
          toast: true,
          showCancelButton: false,
          showConfirmButton: false
        });
      }
    });
  }
  replaceDiacritics(str){

    var diacritics = [
      {char: 'A', base: /[\300-\306]/g},
      {char: 'a', base: /[\340-\346]/g},
      {char: 'E', base: /[\310-\313]/g},
      {char: 'e', base: /[\350-\353]/g},
      {char: 'I', base: /[\314-\317]/g},
      {char: 'i', base: /[\354-\357]/g},
      {char: 'O', base: /[\322-\330]/g},
      {char: 'o', base: /[\362-\370]/g},
      {char: 'U', base: /[\331-\334]/g},
      {char: 'u', base: /[\371-\374]/g},
      {char: 'N', base: /[\321]/g},
      {char: 'n', base: /[\361]/g},
      {char: 'C', base: /[\307]/g},
      {char: 'c', base: /[\347]/g}
    ]
  
    diacritics.forEach(function(letter){
      str = str.replace(letter.base, letter.char);
    });
  
    return str;
  };
  /**
   * Function to filter players
   * @param value
   */
  filterPlayers(value) {
    if (value && value.length >= 3) {
      this.filteredDetail = this.playerList
      ? JSON.parse(this.playerList).filter(
        searchFilter =>{
          let name = searchFilter.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
          let searchValueArr = value.split(" ");
          let matched = true;
          if(searchValueArr.length){
            for(let k of searchValueArr){
              name = this.replaceDiacritics(name);
              
              if(!name.includes(k.toLowerCase())){
                matched = false;
              }
            }
            return matched;
          }else{
            return name.includes(value.toLowerCase());
          }
        }
          // (searchFilter.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase()) ||
          // (searchFilter.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase()) ||
          // (searchFilter.lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase())
      )
      : [];
      // this.filteredDetail = this.playerList
      //   ? JSON.parse(this.playerList).filter(
      //     searchFilter =>
      //       (searchFilter.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase()) ||
      //       (searchFilter.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase()) ||
      //       (searchFilter.lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(value.toLowerCase())
      //   )
      //   : [];
    } else {
      this.filteredDetail = [];
    }
  }

  /**
   * Function to filter chart on click search filters
   */
  onSubmitSearchFilter() {
    this.bubbleChartOptions.series = [{ data: [] }];
    let selectedSeasion = [];
    for (const seasons of this.searchGraphFilterForm.value.minutes) {
      selectedSeasion.push(seasons.id);
    }
    let newData = {
      leagueId: this.searchGraphFilterForm.value.leagueId,
      minutes: selectedSeasion
    };
    let oldData = {
      leagueId: this.metricObject.leagueId,
      minutes: this.metricObject.minutes
    };
    if (JSON.stringify(newData) === JSON.stringify(oldData)) {
      this.metricBubbleGraph(this.metricGraph);
      this.highcharts.chart("container-bubble", this.bubbleChartOptions);
    } else {
      let newDataLeague = {
        leagueId: this.searchGraphFilterForm.value.leagueId
      };
      let oldDataLeague = {
        leagueId: this.metricObject.leagueId
      };
      if (JSON.stringify(newDataLeague) === JSON.stringify(oldDataLeague)) {
        this.getMetricGraph(
          this.playerId,
          this.playerDetails.personal_info.leagueId,
          this.searchGraphFilterForm.value.leagueId,
          selectedSeasion
        );
      } else {
        this.patchLeagueId = false;
        this.getPlayerDetail(this.playerId); // search player on change league
      }
    }
  }

  /**
   * Fet Player details on change season
   * @param event 
   */
  onChangeSeason(seasonValue) {
    this.getSelectedSeason = seasonValue;
    this.getPlayerDetail(this.playerId);
    this.router.navigateByUrl('/player-dashboard/' + this.playerId + '?seasons=' +  seasonValue);
  }
}
