<div class="content player-vs-player">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Player vs Player</li>
        </ol>
    </nav>
    <div class="container-fluid">
        <div class="row no-gutters justify-content-between mb-4">
            <div class="heading">
                <h2 class="mb-0">Player vs Player</h2>
            </div>
            <button class="btn btn-primary" (click)="removePlayer()" *ngIf="openPlayerTwo">
                Reset
            </button>
        </div>
        <div class="search-boxes mb-3">
            <div class="row justify-content-center flex-md-row flex-column">
                <div class="col-xl-6">
                    <form class="mb-3" [formGroup]="searchPlayerOneFilterForm">
                        <div class="row">
                            <div class="form-group col-xl-6">
                                <input type="text" (focus)="focusFunction(1)"
                                    (input)="filterPlayers(searchPlayerOneFilterForm.value.playerOneSearch,1)"
                                    class="form-control search-for-player" placeholder="Search for a player"
                                    formControlName="playerOneSearch" />
                                <div class="spinner-border text-white" role="status" *ngIf="showFilterLoader">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf="filteredDetailOne.length > 0 && playerType == 1" class="playerListOne">
                                    <div class="col-lg-12 p-0 search-player-list">
                                        <p *ngFor="let value of filteredDetailOne" (click)="selectPlayer(value.id,1)">
                                            {{value.fullname}} ({{value.nationality}})</p>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown form-group col-xl-3">
                                <ngx-select formControlName="playerOneSeason" [items]="metric.seasons"
                                    placeholder="Select Season" (select)="onChangeSeason($event,1)">
                                </ngx-select>
                            </div>

                            <div class="col-xl-3">
                                <button (click)="addPlayer()" *ngIf="!openPlayerTwo" class="btn btn-primary w-100"
                                    style="height: 46px;">Add
                                    Player</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 player-search-form" *ngIf="openPlayerTwo">
                    <form class="mb-3" [formGroup]="searchPlayerTwoFilterForm">
                        <div class="row justify-content-end">
                            <div class="dropdown form-group col-xl-3">
                                <ngx-select formControlName="playerTwoSeason" [items]="metric.seasons"
                                    placeholder="Select Season" (select)="onChangeSeason($event,2)">
                                </ngx-select>
                            </div>
                            <div class="form-group col-xl-6">
                                <input type="text" (focus)="focusFunction(2)"
                                    (input)="filterPlayers(searchPlayerTwoFilterForm.value.playerTwoSearch,2)"
                                    class="form-control search-for-player" placeholder="Search for a player"
                                    formControlName="playerTwoSearch" />
                                <div class="spinner-border text-white" role="status" *ngIf="showFilterLoader">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf="filteredDetailTwo.length > 0 && playerType == 2" class="playerListTwo">
                                    <div class="col-lg-12 p-0 search-player-list">
                                        <p *ngFor="let value of filteredDetailTwo" (click)="selectPlayer(value.id,2)">
                                            {{value.fullname}} ({{value.nationality}})</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 mb-4">
                <div class="position-absolute w-100 h-100 data-loader" *ngIf="showLoaderDetailsOne">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="bg-dark-50 d-flex align-items-start px-4 flex-column flex-sm-row">
                    <div class="img mr-sm-3 mb-3 mb-sm-0">
                        <img *ngIf="playerOneDetails && playerOneDetails.personal_info && playerOneDetails.personal_info.photo; else imageNotFound"
                            src="{{playerOneDetails.personal_info.photo}}" alt="" class="rounded-circle" width="100"
                            height="100" />
                        <ng-template #imageNotFound>
                            <img src="../../../assets/images/juve.png" alt="" style="margin-left: -10px;
                        margin-top: 30px;" />
                        </ng-template>

                    </div>
                    <div class="info col p-0">
                        <div>
                            <a routerLink="/player-dashboard/{{playerOneDetails && playerOneDetails.personal_info ? playerOneDetails.personal_info.id :''}}"
                                class="text-primary font-weight-medium mb-0 h2"
                                style="line-height: 1.2;">{{playerOneDetails && playerOneDetails.personal_info ?
                                playerOneDetails.personal_info.name :
                                ''}}</a>
                            <span class="d-block mb-2">Season&nbsp;<span class="player-one-season">{{playerOneSeason}} -
                                    {{playerOneSeason + 1}}</span></span>
                        </div>
                        <div class="row">
                            <div class="col player-data">
                                <span style="display: block;">Minutes Played:
                                    <b class="font-weight-medium">
                                        {{playerOneStats && playerOneStats.games.minutes ? playerOneStats.games.minutes
                                        :0}}
                                    </b>
                                </span>
                                <span>Team:
                                    <b class="font-weight-medium">
                                        {{playerOneTeam ?playerOneTeam : 'N/A'}}
                                    </b>
                                </span>
                            </div>
                            <div class="col player-data">
                                <span style="display: block;">Position:
                                    <b class="font-weight-medium">{{ playerOneStats
                                        && playerOneStats.games ?
                                        playerOneStats.games.position == "Attacker" ? "Forward"
                                        :playerOneStats.games.position : 'N/A'}}</b>
                                </span>
                                <div *ngIf="playerOneLeagues.length > 1" class="inline">
                                    <div class="mr-2">Leagues:</div>
                                    <div class="w-100">
                                        <ngx-select [(ngModel)]="playerOneLeagueId" class="color-wt"
                                            [items]="playerOneLeagues" placeholder="Select League"
                                            (select)="onChangeLeague($event,1)">
                                        </ngx-select>
                                    </div>
                                </div>
                                <span *ngIf="playerOneLeagues.length < 2">League:
                                    <b class="font-weight-medium">
                                        {{playerOneDetails &&
                                        playerOneDetails.personal_info && playerOneDetails.personal_info.league ?
                                        playerOneDetails.personal_info.league.name
                                        : 'N/A'}}
                                    </b>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4 player-search-details" *ngIf="openPlayerTwo">
                <div class="position-absolute w-100 h-100 data-loader" *ngIf="showLoaderDetailsTwo">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="bg-dark-50 d-flex align-items-start px-4 flex-column flex-sm-row">
                    <div class="img mr-sm-3 mb-3 mb-sm-0">
                        <img *ngIf="playerTwoDetails && playerTwoDetails.personal_info && playerTwoDetails.personal_info.photo; else imageNotFound"
                            src="{{playerTwoDetails.personal_info.photo}}" alt="" class="rounded-circle" width="100"
                            height="100" />
                        <ng-template #imageNotFound>
                            <img src="../../../assets/images/juve.png" alt="" style="margin-left: -10px;
                            margin-top: 30px;" />
                        </ng-template>
                    </div>
                    <div class="info col p-0">
                        <div>
                            <a routerLink="/player-dashboard/{{playerTwoDetails && playerTwoDetails.personal_info ? playerTwoDetails.personal_info.id :''}}"
                                class="text-secondary font-weight-medium mb-0 h2"
                                style="line-height: 1.2;">{{playerTwoDetails && playerTwoDetails.personal_info ?
                                playerTwoDetails.personal_info.name :
                                ''}}</a>
                            <span class="d-block mb-2">Season&nbsp;<span class="player-two-season">{{playerTwoSeason}} -
                                    {{playerTwoSeason + 1}}</span></span>
                        </div>
                        <div class="row">
                            <div class="col player-data">
                                <span style="display: block;">Minutes Played: <b
                                        class="font-weight-medium">{{playerTwoStats &&
                                        playerTwoStats.games.minutes ? playerTwoStats.games.minutes :
                                        0}}</b></span>
                                <span>Team:
                                    <b class="font-weight-medium">
                                        {{playerTwoTeam ?playerTwoTeam : 'N/A'}}
                                    </b>
                                </span>
                            </div>
                            <div class="col player-data">
                                <span style="display: block;">Position: <b class="font-weight-medium">{{ playerTwoStats
                                        && playerTwoStats.games ?
                                        playerTwoStats.games.position == "Attacker" ? "Forward"
                                        :playerTwoStats.games.position : 'N/A'}}</b></span>

                                <div *ngIf="playerTwoLeagues.length > 1" class="inline">
                                    <div class="mr-2">Leagues:</div>
                                    <div class="w-100">
                                        <ngx-select [(ngModel)]="playerTwoLeagueId" class="color-wt"
                                            [items]="playerTwoLeagues" placeholder="Select League"
                                            (select)="onChangeLeague($event,2)">
                                        </ngx-select>
                                    </div>
                                </div>
                                <span *ngIf="playerTwoLeagues.length < 2">League:
                                    <b class="font-weight-medium">{{playerTwoDetails &&
                                        playerTwoDetails.personal_info && playerTwoDetails.personal_info.league ?
                                        playerTwoDetails.personal_info.league.name
                                        : 'N/A'}}
                                    </b>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end align-items-center bg-black">
                <div class="dropdown form-group col-xl-2">
                        <angular2-multiselect [data]="allMetrics.ChartMetrics" [(ngModel)]="selectedMetrics"
                            [settings]="metricsDropdownSettings" (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)" >
                        </angular2-multiselect>
                        <!-- <ng-multiselect-dropdown placeholder="" class="btn-xs" [settings]="dropdownSettings"
                         [data]="allMetrics.CompetetionOptions" [(ngModel)]="selectedMetrics" (onSelect)="onOnePositionSelect($event)"
                         (onSelectAll)="onAllPositionSelect($event)" (onDeSelect) = "onPositionDeSelect($event)" >
                    </ng-multiselect-dropdown> -->
                    </div>
            <div class="dropdown form-group col-xl-2">
                <ng-multiselect-dropdown placeholder="" class="btn-xs" [settings]="dropdownSettings"
                    [data]="metric.positions" [(ngModel)]="selectedPositions" (onSelect)="onOnePositionSelect($event)"
                    (onSelectAll)="onAllPositionSelect($event)" (onDeSelect)="onPositionDeSelect($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="dropdown form-group col-xl-2">
                <ngx-select [items]="metric.seasons" class="color-wt" placeholder="Select Season"
                    [(ngModel)]="topSeason" (select)="onChangeTopSeason($event)">
                </ngx-select>
            </div>
        </div>
        <div class="mb-4 radar-chart">
            <div id="container"></div>
            <div class="powered-by" style="font-size: 14px;">Powered by Scout <span style="color:red;">Charts</span>
            </div>
        </div>
    </div>
</div>