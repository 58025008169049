<app-loader *ngIf="showLoader"></app-loader>
<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Search By Metrics</li>
        </ol>
    </nav>
    <div class="container-fluid">
        <form [formGroup]="searchMetricForm" (ngSubmit)="onSubmitSearchMetric()">
            <div class="heading">
                <h2>Players Metrics</h2>
            </div>
            <div class="row mb-4">
                <div class="col-xl-2 mb-4 mb-xl-0 col-lg-4">
                    <ngx-select [items]="metric.seasons" formControlName="seasons" placeholder="Select Season">
                    </ngx-select>
                </div>
                <div class="col-xl-2 mb-4 mb-xl-0 col-lg-4">
                    <div class="multiselect-drop-down mr-md-3 flex-grow-1 mb-3 mb-md-0">
                        <ng-multiselect-dropdown placeholder="" class="btn-xs" [settings]="dropdownSettings"
                            formControlName="leagues" [data]="metric.leagues" [(ngModel)]="selectedLeague">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="col-xl-2 mb-4 mb-xl-0 col-lg-4">
                    <div class="multiselect-drop-down mr-md-3 flex-grow-1 mb-3 mb-md-0">
                        <ng-multiselect-dropdown placeholder="" class="btn-xs" [settings]="dropdownSettings"
                            formControlName="position" [data]="metric.positions" [(ngModel)]="selectedPositions">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="col-xl-3 mb-4 mb-xl-0 col-lg-4">
                    <label>Age</label>
                    <div class="range-slider">
                        <ngx-slider formControlName="age" [(value)]="minValue" [(highValue)]="maxValue"
                            [options]="optionsAge"></ngx-slider>
                    </div>
                </div>
                <div class="col-xl-3 mb-4 mb-xl-0 col-lg-4">
                    <label>Minutes Played</label>
                    <div class="range-slider">
                        <ngx-slider formControlName="minutes" [(value)]="minMinuteValue" [(highValue)]="maxMinuteValue"
                            [options]="optionsPlayedMinutes"></ngx-slider>
                    </div>
                </div>
            </div>
            <div class="row mb-xl-4">
                <div class="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                    <div class="bg-dark-50 px-4" *ngIf="isFilterFetched">
                        <div class="card-header d-flex justify-content-between align-items-center mb-3">
                            <h3 class="mb-0 font-weight-medium">Attacking</h3>
                            <img src="../../../assets/images/attacking.svg" alt="">
                        </div>
                        <label>Goals (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="goals" [(value)]="sliderRangeValues['goalsPer90'].floor"
                                [(highValue)]="sliderRangeValues['goalsPer90'].ceil"
                                [options]="sliderRangeValues['goalsPer90']">
                            </ngx-slider>
                        </div>
                        <label>Non penality goals (per 90min)</label>
                            <div class="range-slider">
                                <ngx-slider formControlName="nonPenalityGoal" [(value)]="sliderRangeValues['nonPenalityGoalPer90'].floor"
                                    [(highValue)]="sliderRangeValues['nonPenalityGoalPer90'].ceil"
                                    [options]="sliderRangeValues['nonPenalityGoalPer90']">
                                </ngx-slider>
                            </div>
                        <label>Shots on target (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="shotsOnTarget"
                                [(value)]="sliderRangeValues['shotsOnTargetPer90'].floor"
                                [(highValue)]="sliderRangeValues['shotsOnTargetPer90'].ceil"
                                [options]="sliderRangeValues['shotsOnTargetPer90']">
                            </ngx-slider>
                        </div>
                        <label>Successful dribbles (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="successDribble"
                                [(value)]="sliderRangeValues['successfulDribblesPer90'].floor"
                                [(highValue)]="sliderRangeValues['successfulDribblesPer90'].ceil"
                                [options]="sliderRangeValues['successfulDribblesPer90']">
                            </ngx-slider>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                    <div class="bg-dark-50 px-4" *ngIf="isFilterFetched">
                        <div class="card-header d-flex justify-content-between align-items-center mb-3">
                            <h3 class="mb-0 font-weight-medium">Defending</h3>
                            <img src="../../../assets/images/defending.svg" alt="">
                        </div>
                        <label>Tackles (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="tackles" [(value)]="sliderRangeValues['tacklesPer90'].floor"
                                [(highValue)]="sliderRangeValues['tacklesPer90'].ceil"
                                [options]="sliderRangeValues['tacklesPer90']">
                            </ngx-slider>
                        </div>
                        <label>Duels Won (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="duelWon" [(value)]="sliderRangeValues['wonDuelsPer90'].floor"
                                [(highValue)]="sliderRangeValues['wonDuelsPer90'].ceil"
                                [options]="sliderRangeValues['wonDuelsPer90']">
                            </ngx-slider>
                        </div>
                        <label>Interceptions (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="interceptions"
                                [(value)]="sliderRangeValues['interceptionsPer90'].floor"
                                [(highValue)]="sliderRangeValues['interceptionsPer90'].ceil"
                                [options]="sliderRangeValues['interceptionsPer90']">
                            </ngx-slider>
                        </div>
                        <label>Blocked shots (per 90min)</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="blockedShots"
                                [(value)]="sliderRangeValues['blocksPer90'].floor"
                                [(highValue)]="sliderRangeValues['blocksPer90'].ceil"
                                [options]="sliderRangeValues['blocksPer90']">
                            </ngx-slider>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                    <div class="bg-dark-50 px-4 h-100" *ngIf="isFilterFetched">
                        <div>
                            <div class="card-header d-flex justify-content-between align-items-center mb-3">
                                <h3 class="mb-0 font-weight-medium">Passing</h3>
                                <img src="../../../assets/images/passing.svg" alt="">
                            </div>
                            <label>Total passes (per 90min)</label>
                            <div class="range-slider">
                                <ngx-slider formControlName="totalPasses"
                                    [(value)]="sliderRangeValues['totalPassesPer90'].floor"
                                    [(highValue)]="sliderRangeValues['totalPassesPer90'].ceil"
                                    [options]="sliderRangeValues['totalPassesPer90']">
                                </ngx-slider>
                            </div>
                            <label>Key Passes (per 90min)</label>
                            <div class="range-slider">
                                <ngx-slider formControlName="keyPasses"
                                    [(value)]="sliderRangeValues['keyPassesPer90'].floor"
                                    [(highValue)]="sliderRangeValues['keyPassesPer90'].ceil"
                                    [options]="sliderRangeValues['keyPassesPer90']">
                                </ngx-slider>
                            </div>

                            <label>Accuracy (per game)</label>
                            <div class="range-slider">
                                <ngx-slider formControlName="accuracy" [(value)]="sliderRangeValues['accuracy'].floor"
                                    [(highValue)]="sliderRangeValues['accuracy'].ceil"
                                    [options]="sliderRangeValues['accuracy']">
                                </ngx-slider>
                            </div>
                            <label>Assists (per 90min)</label>
                            <div class="range-slider">
                                <ngx-slider formControlName="assists" [(value)]="sliderRangeValues['assistsPer90'].floor"
                                    [(highValue)]="sliderRangeValues['assistsPer90'].ceil"
                                    [options]="sliderRangeValues['assistsPer90']">
                                </ngx-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                    <div class="bg-dark-50 px-4" *ngIf="isFilterFetched">
                        <div class="card-header d-flex justify-content-between align-items-center mb-3">
                            <h3 class="mb-0 font-weight-medium">Discipline</h3>
                            <img src="../../../assets/images/discipline.svg" alt="">
                        </div>
                        <label>Yellow cards</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="yellowCards" [(value)]="sliderRangeValues['yellow'].floor"
                                [(highValue)]="sliderRangeValues['yellow'].ceil"
                                [options]="sliderRangeValues['yellow']">
                            </ngx-slider>
                        </div>
                        <label>Red cards</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="redCards" [(value)]="sliderRangeValues['red'].floor"
                                [(highValue)]="sliderRangeValues['red'].ceil" [options]="sliderRangeValues['red']">
                            </ngx-slider>
                        </div>
                        <label>Fouls</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="fouls" [(value)]="sliderRangeValues['fouls'].floor"
                                [(highValue)]="sliderRangeValues['fouls'].ceil" [options]="sliderRangeValues['fouls']">
                            </ngx-slider>
                        </div>
                        <label>Penalties committed</label>
                        <div class="range-slider">
                            <ngx-slider formControlName="panaltiesCommitted"
                                [(value)]="sliderRangeValues['panalityCommitted'].floor"
                                [(highValue)]="sliderRangeValues['panalityCommitted'].ceil"
                                [options]="sliderRangeValues['panalityCommitted']">
                            </ngx-slider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mb-4">
                <button type="submit" class="btn btn-primary" style="height: 46px;min-width: 120px;">Search</button>
            </div>
        </form>
        <div class="table-responsive">
            <ng2-smart-table [settings]="settings" [source]="searchMetrixPlayerList" class="ng-smart"></ng2-smart-table>
        </div>
    </div>
</div>