<div class="content">
    <nav>
        <ol class="breadcrumb bg-transparent">
            <li class="breadcrumb-item" routerLink="/"><a routerLink="">Home</a></li>
            <li class="breadcrumb-item active">Privacy Policy</li>
        </ol>
    </nav>

    <div class="container-md container" id="about">
        <div class="heading mb-4">
            <h2>Privacy Policy</h2>
        </div>
        <p>
            No personal data is collected from the site visitors. It is physically not possible. The site does not require registration and we do not obtain any personal information from visitors. 
        </p>
    </div>
</div>