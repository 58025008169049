export class AppApi {
  static playersList = "https://8ypvb6lpbk.execute-api.us-east-1.amazonaws.com/live/players-list";
  static playerDetail = "https://ajix94oy89.execute-api.us-east-1.amazonaws.com/live/player-detail";
  // static playerDetail = "http://localhost:3000/player-detail";
  static matricList = "https://jwmqpsn4d6.execute-api.us-east-1.amazonaws.com/live/metrics";
  // static matricList = "http://localhost:3000/player_metrics";
  
  static searchMatrix = "https://dqzt15f9r7.execute-api.us-east-1.amazonaws.com/live/search-by-metrics";
  static metricsFilterUrl = "https://n15xu4id6f.execute-api.us-east-1.amazonaws.com/live/metrics-filter";
  // static topLeagues = "http://localhost:3000/get-top-leagues-per90-stats";
  static topLeagues = "https://cmc7vk4nyg.execute-api.us-east-1.amazonaws.com/live/get-top-leagues-per90-stats";
  static sendPlayerEmail = "https://3aejdfia5i.execute-api.us-east-1.amazonaws.com/live/send_playervsplayer_email";
}
