import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PlayerService } from 'src/app/services/player/player.service';
import { AppApi } from 'src/app/app-api';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-static-player-vs-player',
  templateUrl: './static-player-vs-player.component.html',
  styleUrls: ['./static-player-vs-player.component.scss']
})
export class StaticPlayerVsPlayerComponent implements OnInit {
  modalRef?: BsModalRef;
  alertbx = false;
  submitted = false;
  loader: any = false;
  accessForm: FormGroup
  hideShow: any = true;
  constructor(private modalService: BsModalService, private fb: FormBuilder, private playerService: PlayerService) { }

  openDialog(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.alertbx = false
  }

  closedialog(template: TemplateRef<any>) {
    this.modalRef?.hide()
    this.accessForm.reset()
  }

  ngOnInit(): void {
    this.accessForm = this.fb.group({
      email: new FormControl("", [Validators.email, Validators.required]),
      interest: ['', Validators.required]
    })
  }
  get fc() {
    return this.accessForm.controls
  }

  onSubmit() {
    this.submitted = true;
    if (this.accessForm.valid) {
      this.loader = true;
      this.playerService.postApiWitoutToken(AppApi.sendPlayerEmail, this.accessForm.value).then(res => {
        this.loader = false;
        if (res) {
          this.modalRef?.hide();
          Swal.fire({
            html: `<strong>Thank you</strong> for your interest! We’re currently updating the tool and working hard to put the
            finishing touches. Things are going well and it should be ready very soon. Our team will be reaching out to
            you
            with
            updates.`,
            showCloseButton: true,
          })
          this.accessForm.reset();
        } else {
          this.loader = false;
        }
      })
    } else {
      return;
    }
  }

  cross() {
    this.alertbx = false;
  }
}





